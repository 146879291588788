import React from 'react';
import styles from './DexScreenerEmbed.module.css';

interface DexScreenerEmbedProps {
  contractAddress: string;
  chain: string;
  onClose?: () => void;
  isMobileView?: boolean;
}

const DexScreenerEmbed: React.FC<DexScreenerEmbedProps> = ({ 
  contractAddress, 
  chain, 
  onClose,
  isMobileView = false 
}) => {
  const embedUrl = `https://dexscreener.com/${chain}/${contractAddress}?embed=1&theme=dark`;

  // Combined click/touch handler to ensure it works on all devices
  const handleCloseClick = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClose) onClose();
  };

  return (
    <div className={isMobileView ? styles.mobileContainer : styles.container}>
      {/* Always show the close button, regardless of mobile or desktop */}
      {onClose && (
        <button 
          className={styles.closeButton} 
          onClick={handleCloseClick}
          onTouchEnd={handleCloseClick}
          aria-label="Close chart"
        >
          ×
        </button>
      )}
      <div className={styles.embedWrapper}>
        <iframe 
          src={embedUrl} 
          title="DexScreener Embed" 
          className={styles.iframe}
        ></iframe>
      </div>
    </div>
  );
};

export default DexScreenerEmbed;