import React, { useState, useEffect } from 'react';
import styles from '../styles/Watchlist.module.css';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import DexScreenerEmbed from './DexScreenerEmbed';
import PermanentOrdinalItem from './PermOrdinal';

const API_URL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === 'development' ? 'http://localhost:5008' : 'https://api.argothepuppy.org');

interface ChartData {
  timestamp: number;
  price: number;
  volume?: number;
}

type ChainType = 'solana' | 'ethereum' | 'base' | 'bsc';
type WatchlistItemType = 'token' | 'nft' | 'ordinal' | 'rune' | 'brc20';
type PriceUnit = 'USD' | 'BTC' | 'SOL' | 'ETH';

interface WatchlistItem {
  id: number;
  type: WatchlistItemType;
  name: string;
  address: string;
  chain: ChainType;
  collectionSlug?: string;
  runeSymbol?: string;
  price?: string | number;
  priceUnit?: PriceUnit;
  ticker: string;
  brc20_ticker?: string;
  onOpenDexScreener: (address: string, chain: string) => void;
  ordinalAddress: string;
  onClose: () => void;
}

interface WatchlistPanelProps {
  onOpenDexScreener: (address: string, chain: string) => void;
  ordinalAddress: string;
  onClose: () => void;
}

interface CategoryState {
  [key: string]: boolean;
}

interface PriceData {
  price: string | number;
  unit: PriceUnit;
}

const ME_API_KEY = process.env.REACT_APP_ME_API_KEY;

const WatchlistPanel: React.FC<WatchlistPanelProps> = ({ onOpenDexScreener, ordinalAddress, onClose }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [watchlistItems, setWatchlistItems] = useState<WatchlistItem[]>([]);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<WatchlistItem | null>(null);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [showMobileDexScreener, setShowMobileDexScreener] = useState<boolean>(false);
  const [selectedTokenAddress, setSelectedTokenAddress] = useState<string>('');
  const [selectedTokenChain, setSelectedTokenChain] = useState<string>('');
  const isMobile = window.innerWidth <= 768;
  const [categoryStates, setCategoryStates] = useState<CategoryState>({
    token: true,
    nft: true,
    ordinal: true,
    rune: true,
    brc20: true
  });
  const [newItem, setNewItem] = useState<{
    type: WatchlistItemType;
    name: string;
    address: string;
    chain: ChainType;
    collectionSlug: string;
    runeSymbol: string;
    brc20_ticker?: string;
  }>({
    type: 'token',
    name: '',
    address: '',
    chain: 'solana',
    collectionSlug: '',
    runeSymbol: '',
    brc20_ticker: ''
  });

  const [chainStates, setChainStates] = useState<CategoryState>({
    solana: true,
    ethereum: true,
    base: true,
    bsc: true
  });

  const toggleChain = (chain: string) => {
    setChainStates(prev => ({
      ...prev,
      [chain]: !prev[chain]
    }));
  };

  const chainLabels: { [key: string]: string } = {
    solana: 'Solana',
    ethereum: 'Ethereum',
    base: 'Base',
    bsc: 'BSC'
  };
  const [runeInput, setRuneInput] = useState('');

  useEffect(() => {
    const loadWatchlist = async () => {
      if (!ordinalAddress) return;

      try {
        const response = await fetch(`${API_URL}/api/watchlist/${ordinalAddress}`);
        if (!response.ok) throw new Error('Failed to fetch watchlist');

        const data = await response.json();
        setWatchlistItems(data);
      } catch (error) {
        console.error('Error loading watchlist:', error);
      }
    };

    loadWatchlist();
  }, [ordinalAddress]);

  const formatRuneSymbol = (symbol: string): string => {
    return symbol.trim().toUpperCase().replace(/\s+/g, '•');
  };

  // Updated fetchNFTFloor function to use collection address for Ethereum NFTs
  const fetchNFTFloor = async (collectionSlug: string, chain: string): Promise<PriceData> => {
    try {
      let response;

      if (chain === 'solana') {
        response = await fetch(
          `${API_URL}/api/proxy/magiceden/collections/${collectionSlug}/stats`
        );
      } else if (chain === 'ethereum') {
        // Use the collection address directly instead of slug for Ethereum
        response = await fetch(
          `${API_URL}/api/proxy/magiceden/ethereum/collections/${collectionSlug}`
        );
      } else {
        throw new Error('Unsupported blockchain');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      let floorPrice;

      if (chain === 'solana') {
        floorPrice = data.floorPrice / 1000000000;
      } else if (chain === 'ethereum') {
        floorPrice = data.collections[0].floorAsk.price.amount.native;
      }

      return {
        price: floorPrice,
        unit: chain === 'solana' ? 'SOL' : 'ETH'
      };
    } catch (error) {
      console.error('Error fetching NFT floor:', error);
      return { price: 'Error', unit: chain === 'solana' ? 'SOL' : 'ETH' };
    }
  };

  // Comment out BRC20 fetching function
  /*
  const fetchBRC20Price = async (ticker: string): Promise<PriceData> => {
    try {
      const response = await fetch(
        `${API_URL}/api/proxy/bis/brc20/${ticker}/info`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("BRC20 Ticker Info response:", data);
      if (data.data && data.data.min_listed_unit_price) {
        const price = data.data.min_listed_unit_price / 100000000;
        return { price: parseFloat(price.toFixed(8)), unit: 'BTC' };
      }
      return { price: 'N/A', unit: 'BTC' };
    } catch (error) {
      console.error('Error fetching BRC-20 ticker info:', error);
      return { price: 'Error', unit: 'BTC' };
    }
  };
  */

  const fetchRunePrice = async (runeSymbol: string): Promise<PriceData> => {
    try {
      const formattedRuneSymbol = runeSymbol.replace(/•/g, '');

      const response = await fetch(
        `${API_URL}/api/proxy/magiceden/ord/btc/runes/market/${formattedRuneSymbol}/info`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const floorPrice = data.floorUnitPrice ? parseFloat(data.floorUnitPrice.value) : 0;

      return {
        price: floorPrice,
        unit: 'BTC'
      };
    } catch (error) {
      console.error('Error fetching rune price:', error);
      return { price: 'Error', unit: 'BTC' };
    }
  };

  const fetchWithRetry = async (
    fetchFn: () => Promise<PriceData>,
    maxRetries = 3,
    delay = 1000
  ): Promise<PriceData> => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        const result = await fetchFn();
        if (result.price !== 'Rate Limited') {
          return result;
        }
        await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, i)));
      } catch (error) {
        if (i === maxRetries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, i)));
      }
    }
    return { price: 'Failed after retries', unit: 'BTC' };
  };

  const fetchOrdinalFloor = async (collectionSlug: string): Promise<PriceData> => {
    try {
      const response = await fetch(
        `${API_URL}/api/proxy/magiceden/ord/btc/stat?collectionSymbol=${collectionSlug}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return { price: data.floorPrice / 100000000, unit: 'BTC' };
    } catch (error) {
      console.error('Error fetching ordinal floor:', error);
      return { price: 'Error', unit: 'BTC' };
    }
  };

  const fetchTokenPrice = async (address: string): Promise<PriceData> => {
    try {
      const response = await fetch(
        `https://api.dexscreener.io/latest/dex/tokens/${address}`
      );
      const data = await response.json();

      if (!data.pairs || data.pairs.length === 0) {
        return { price: 'N/A', unit: 'USD' };
      }

      const relevantPair = data.pairs[0];
      return { price: relevantPair.priceUsd || 'N/A', unit: 'USD' };
    } catch (error) {
      console.error('Error fetching token price:', error);
      return { price: 'Error', unit: 'USD' };
    }
  };

  useEffect(() => {
    const fetchPrices = async () => {
      if (watchlistItems.length === 0) return;

      const updatedItems = await Promise.all(
        watchlistItems.map(async (item) => {
          try {
            let priceData: PriceData;

            switch (item.type) {
              case 'token':
                priceData = await fetchTokenPrice(item.address);
                priceData.unit = 'USD';
                break;
              case 'nft':
                priceData = item.collectionSlug ?
                  await fetchNFTFloor(item.collectionSlug, item.chain) :
                  { price: 'N/A', unit: item.chain === 'solana' ? 'SOL' : 'ETH' };
                break;
              case 'ordinal':
                priceData = item.collectionSlug ?
                  await fetchOrdinalFloor(item.collectionSlug) :
                  { price: 'N/A', unit: 'BTC' };
                break;
              case 'rune':
                priceData = item.runeSymbol ?
                  await fetchRunePrice(item.runeSymbol) :
                  { price: 'N/A', unit: 'BTC' };
                break;
              /* Commented out BRC20 as requested
              case 'brc20':
                console.log('Fetching BRC-20 price for:', item.brc20_ticker);
                priceData = item.brc20_ticker
                  ? await fetchBRC20Price(item.brc20_ticker)
                  : { price: 'N/A', unit: 'BTC' };
                break;
              */
              default:
                priceData = { price: 'N/A', unit: 'USD' };
            }

            return {
              ...item,
              price: priceData.price,
              priceUnit: priceData.unit
            };
          } catch (error) {
            console.error(`Error fetching price for ${item.type}:`, error);
            return {
              ...item,
              price: 'Error',
              priceUnit: (item.type === 'token' ? 'USD' : 'BTC') as PriceUnit
            };
          }
        })
      );

      setWatchlistItems(updatedItems);
    };

    fetchPrices();
    const interval = setInterval(fetchPrices, 60000);
    return () => clearInterval(interval);
  }, [watchlistItems.length]);

  const toggleCategory = (category: string) => {
    setCategoryStates(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const categorizedItems = {
    token: watchlistItems.filter(item => item.type === 'token'),
    nft: watchlistItems.filter(item => item.type === 'nft'),
    ordinal: watchlistItems.filter(item => item.type === 'ordinal'),
    rune: watchlistItems.filter(item => item.type === 'rune'),
    brc20: watchlistItems.filter(item => item.type === 'brc20')
  };

  const categoryLabels = {
    token: 'Tokens',
    nft: 'NFTs',
    ordinal: 'Ordinals',
    rune: 'Runes',
    brc20: 'BRC-20'
  };

  const handleAddItem = async () => {
    console.log("New Item Data:", newItem);

    if (
      !newItem.name ||
      (newItem.type === 'token' && !newItem.address) ||
      (newItem.type === 'rune' && !newItem.runeSymbol) ||
      (newItem.type === 'nft' && !newItem.collectionSlug) ||
      (newItem.type === 'brc20' && !newItem.brc20_ticker)
    ) {
      return;
    }

    const newWatchlistItem = {
      type: newItem.type,
      name: newItem.name,
      address: newItem.type === 'brc20' ? '' : newItem.address || '',
      chain: newItem.type === 'brc20' || newItem.type === 'rune' ? '' : newItem.chain,
      collectionSlug: newItem.collectionSlug || '',
      runeSymbol: newItem.type === 'rune' ? newItem.runeSymbol : '',
      brc20_ticker: newItem.type === 'brc20' ? newItem.brc20_ticker : ''
    };

    try {
      const response = await fetch(`${API_URL}/api/watchlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          wallet_address: ordinalAddress,
          ...newWatchlistItem,
        }),
      });

      if (!response.ok) throw new Error('Failed to add watchlist item');

      const savedItem = await response.json();
      setWatchlistItems(prev => [...prev, savedItem]);

      setNewItem({
        type: 'token',
        name: '',
        address: '',
        chain: 'solana',
        collectionSlug: '',
        runeSymbol: '',
        brc20_ticker: '',
      });
      setRuneInput('');
      setShowAddModal(false);
    } catch (error) {
      console.error('Error adding watchlist item:', error);
    }
  };

  const handleRemoveItem = async (id: number) => {
    if (!ordinalAddress) return;

    try {
      const response = await fetch(`${API_URL}/api/watchlist/${ordinalAddress}/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Failed to delete watchlist item');
      setWatchlistItems(prev => prev.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error removing watchlist item:', error);
    }
  };

  const handleTypeChange = (type: WatchlistItemType) => {
    setNewItem({
      ...newItem,
      type,
      address: '',
      collectionSlug: '',
      runeSymbol: '',
      chain: type === 'nft' ? 'solana' : 'solana',
    });
    setRuneInput('');
  };

  // Update the handleItemClick function
  const handleItemClick = (item: WatchlistItem) => {
    if (item.type === 'token') {
      if (isMobile) {
        // For mobile: Show the DexScreener as an overlay on the watchlist
        setSelectedTokenAddress(item.address);
        setSelectedTokenChain(item.chain);
        setShowMobileDexScreener(true);
      } else {
        // For desktop: Use the existing function to show DexScreener in the chat area
        onOpenDexScreener(item.address, item.chain);
      }
    } else {
      setSelectedItem(item);
    }
  };

  // Add this function to close the mobile DexScreener
  const closeMobileDexScreener = () => {
    setShowMobileDexScreener(false);
  };

  const formatPrice = (item: WatchlistItem): JSX.Element | string => {
    const { price, priceUnit } = item;

    if (typeof price === 'string' && ['Error', 'N/A'].includes(price)) {
      return price;
    }

    const priceValue = typeof price === 'number' ? price : Number(price);
    if (isNaN(priceValue)) return 'N/A';

    if (item.type === 'token' || item.type === 'rune') {
      const priceStr = priceValue.toFixed(14);
      const [leading, decimals] = priceStr.split('.');

      if (decimals) {
        const match = decimals.match(/^(0{1,9})(\d+)/);
        if (match) {
          const zeroCount = match[1].length;
          const mainDecimals = match[2].slice(0, 4);

          return (
            <>
              {leading}.0<sub>{zeroCount}</sub>{mainDecimals} {priceUnit}
            </>
          );
        }
      }
      return `${priceValue.toFixed(4)} ${priceUnit}`;
    }

    if (item.type === 'nft' || item.type === 'ordinal') {
      return `${priceValue.toFixed(5).replace(/\.?0+$/, '')} ${priceUnit}`;
    }

    return `${priceValue.toFixed(2)} ${priceUnit}`;
  };

  const renderCategory = (type: WatchlistItemType) => {
    const items = categorizedItems[type];
    
    // Skip rendering categories with no items (but we'll add special handling for ordinals)
    if (items.length === 0 && type !== 'ordinal') return null;
  
    // Skip rendering BRC20 category
    if (type === 'brc20') return null;
  
    return (
      <div key={type} className={styles.categoryContent}>
        <div
          className={styles.categoryHeader}
          onClick={() => toggleCategory(type)}
        >
          <span className={styles.categoryTitle}>
            <span className={`${styles.collapseIcon} ${!categoryStates[type] ? styles.collapsed : ''}`}>
              ▼
            </span>
            {categoryLabels[type]} ({type === 'ordinal' ? (items.length + 1) : items.length})
          </span>
        </div>
  
        {categoryStates[type] && (
          <div className={styles.itemsList}>
            {type === 'token' ? (
              renderTokensByChain(items)
            ) : type === 'ordinal' ? (
              // For ordinals, show both the permanent item and user-added items
              <>
                <PermanentOrdinalItem 
                  onOpenPriceChart={() => {
                    // Handle clicking the permanent ordinal
                    // You could create a specific handler or use the same one as other ordinals
                  }}
                />
                {items.map((item) => (
                  <div
                    key={item.id}
                    className={styles.item}
                    onClick={() => handleItemClick(item)}
                  >
                    <div className={styles.itemContent}>
                      <span className={styles.itemName}>{item.name}
                        <button
                          className={styles.removeButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveItem(item.id);
                          }}
                        >
                          ×
                        </button>
                      </span>
                      <span className={styles.itemPrice}>
                        {formatPrice(item)}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              // Normal rendering for other types
              items.map((item) => (
                <div
                  key={item.id}
                  className={styles.item}
                  onClick={() => handleItemClick(item)}
                >
                  <div className={styles.itemContent}>
                    <span className={styles.itemName}>{item.name}
                      <button
                        className={styles.removeButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveItem(item.id);
                        }}
                      >
                        ×
                      </button>
                    </span>
                    <span className={styles.itemPrice}>
                      {formatPrice(item)}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    );
  };

  const renderTokensByChain = (items: WatchlistItem[]) => {
    const tokensByChain = {
      solana: items.filter(item => item.type === 'token' && item.chain === 'solana'),
      ethereum: items.filter(item => item.type === 'token' && item.chain === 'ethereum'),
      base: items.filter(item => item.type === 'token' && item.chain === 'base'),
      bsc: items.filter(item => item.type === 'token' && item.chain === 'bsc')
    };

    return Object.entries(tokensByChain).map(([chain, chainItems]) => {
      if (chainItems.length === 0) return null;

      return (
        <div key={chain} className={styles.categoryHeader2}>
          <div
            className={styles.chainHeader}
            onClick={() => toggleChain(chain)}
          >
            <span className={styles.chainTitle}>
              <span className={`${styles.collapseIcon} ${!chainStates[chain] ? styles.collapsed : ''}`}>
                ▼
              </span>
              {chainLabels[chain]} ({chainItems.length})
            </span>
          </div>

          {chainStates[chain] && (
            <div className={styles.chainItems}>
              {chainItems.map((item) => (
                <div
                  key={item.id}
                  className={styles.item}
                  onClick={() => handleItemClick(item)}
                >
                  <div className={styles.itemContent}>
                    <span className={styles.itemName}>{item.name}
                      <button
                        className={styles.removeButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveItem(item.id);
                        }}
                      >
                        ×
                      </button>
                    </span>
                    <span className={styles.itemPrice}>
                      {formatPrice(item)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className={`${styles.watchlistContainer} ${isExpanded ? styles.expanded : styles.collapsed}`}>
      {!isExpanded && (
        <button className={styles.expandButton} onClick={() => setIsExpanded(true)}>
          Expand
        </button>
      )}
      {isExpanded && (
        <div className={styles.watchlistContent}>
          <div className={styles.header3}>
            <h2 className={styles.title}>Watchlist</h2>
            <div className={styles.headerButtons}>
              <button className={styles.addButton} onClick={() => setShowAddModal(true)}>
                Add Item
              </button>
              <button
                className={styles.mobileOnly}
                onClick={onClose}
              >
                Hide Watchlist
              </button>
            </div>
          </div>
          <div className={styles.items}>
            {renderCategory('token')}
            {renderCategory('nft')}
            {renderCategory('ordinal')}
            {renderCategory('rune')}
            {/* Hide BRC20 category for now */}
            {/* {renderCategory('brc20')} */}
          </div>
        </div>
      )}
      {showAddModal && (
        <div className={styles.addModal}>
          <h3>Add to Watchlist</h3>
          <select className={styles.selector}
            value={newItem.type}
            onChange={(e) => handleTypeChange(e.target.value as WatchlistItemType)}
          >
            <option className={styles.addoption} value="token">Token</option>
            <option value="ordinal">Ordinal</option>
            <option value="nft">NFT</option>
            <option value="rune">Rune</option>
            {/* Keep BRC20 option commented out */}
            {/* <option value="brc20">BRC-20</option> */}
          </select>
          <input className={styles.selector} 
            type="text"
            placeholder="Name"
            value={newItem.name}
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
          />
          {newItem.type === 'token' && (
            <input className={styles.selector}
              type="text"
              placeholder="Contract Address"
              value={newItem.address}
              onChange={(e) => setNewItem({ ...newItem, address: e.target.value })}
            />
          )}
          {(newItem.type === 'ordinal' || newItem.type === 'nft') && (
            <input className={styles.selector}
              type="text"
              placeholder={newItem.type === 'nft' && newItem.chain === 'ethereum'
                ? "Collection Address (0x...)"
                : "Collection Slug"}
              value={newItem.collectionSlug}
              onChange={(e) => setNewItem({ ...newItem, collectionSlug: e.target.value })}
            />
          )}
          {newItem.type === 'rune' && (
            <input className={styles.selector}
              type="text"
              placeholder="Rune Symbol (words will be joined with •)"
              value={runeInput}
              onChange={(e) => {
                const value = e.target.value;
                setRuneInput(value);
                setNewItem(prev => ({ ...prev, runeSymbol: formatRuneSymbol(value) }));
              }}
            />
          )}
          {/* Keep BRC20 input commented out
          {newItem.type === 'brc20' && (
            <input
              type="text"
              placeholder="BRC-20 Ticker Symbol"
              value={newItem.brc20_ticker}
              onChange={(e) => setNewItem({ ...newItem, brc20_ticker: e.target.value })}
            />
          )}
          */}
          {(newItem.type === 'token' || newItem.type === 'nft') && (
            <select className={styles.selector}
              value={newItem.chain}
              onChange={(e) => setNewItem({ ...newItem, chain: e.target.value as ChainType })}
            >
              <option  value="solana">Solana</option>
              {newItem.type === 'nft' && <option value="ethereum">Ethereum</option>}
              {newItem.type === 'token' && (
                <>
                  <option value="ethereum">Ethereum</option>
                  <option value="base">Base</option>
                  <option value="bsc">BSC</option>
                </>
              )}
            </select>
          )}
          <div className={styles.buttonGroup}>
            <button className={styles.buttonCancel} onClick={() => setShowAddModal(false)}>Cancel</button>
            <button className={styles.buttonSubmit} onClick={handleAddItem}>Add</button>
          </div>
        </div>
      )}
      {showMobileDexScreener && (
        <DexScreenerEmbed
          contractAddress={selectedTokenAddress}
          chain={selectedTokenChain}
          onClose={closeMobileDexScreener}
          isMobileView={true}
        />
      )}
    </div>
  );
};

export default WatchlistPanel;