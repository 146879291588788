import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/Home.module.css';

interface WalletInfo {
  wallet: string;
  phase: string;
}

const MobileMap2: React.FC = () => {
  const [walletInput, setWalletInput] = useState('');
  const [walletStatus, setWalletStatus] = useState<string | null>(null);
  const [walletList, setWalletList] = useState<WalletInfo[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isWalletCheck, setIsWalletCheck] = useState(false); // To differentiate wallet check from other popups
  const [tweetUrl, setTweetUrl] = useState<string | null>(null); // To store Twitter URL
  const [showOverlay, setShowOverlay] = useState(false); // For the new overlay
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // For the image gallery
  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0); // For paragraph switching

  // Array of gallery images
  const galleryImages = [
    '0.png',
    'argo1.jpg',
    'argo2.jpg',
    'argo3.jpg',
    'argo4.jpg',
    'argo5.jpg',
    'argo6.jpg',
    'argo7.jpg',
  ];

  // Cycle through the images every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
    }, 2000); // Change image every 2 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [galleryImages.length]);

  // Load wallet list from the CSV file in the public folder
  useEffect(() => {
    const loadWalletList = async () => {
      try {
        const response = await fetch('/argotest.csv'); // Fetch CSV directly from public folder
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const content = await response.text();
        const lines = content.split('\n').filter(line => line.trim() !== '');

        // Map each line to a wallet and phase (column A is wallet, column B is phase)
        const data = lines.map(line => {
          const [wallet, phase] = line.split(',').map(item => item.trim()); // Get both wallet and phase (column B)
          return { wallet: wallet.toLowerCase(), phase }; // Store wallet in lowercase for easier comparison
        });

        setWalletList(data); // Set the wallet list state
      } catch (error) {
        console.error('Error loading wallet list:', error);
      }
    };

    loadWalletList(); // Load wallet list on component mount
  }, []);

  const handleWalletCheck = () => {
    const walletInfo = walletList.find(item => item.wallet === walletInput.trim().toLowerCase());

    if (walletInfo) {
      setWalletStatus('onList');

      // Conditionally set the popup message based on the phase
      if (walletInfo.phase === 'GTD') {
        setPopupMessage('You are on the Guaranteed list! Woof!');
      } else if (walletInfo.phase === 'FCFS') {
        setPopupMessage('You are on the FCFS list! Woof!');
      } else {
        setPopupMessage(`Congrats! You are on the ${walletInfo.phase} list!`);
      }

      // Generate the Twitter intent URL with the message
      const tweetMessage = `Woof! @argothepuppy is minting on Oct 3rd, 12:00 pm EST and I'm in!`;
      const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetMessage)}&url=https://argothepuppy.org`; // Link to your website with meta tags
      setTweetUrl(url); // Store URL for Tweet button

    } else {
      setWalletStatus('notOnList');
      setPopupMessage('Sorry, you are not on the list.');
      setTweetUrl(null); // Clear Tweet URL if not on the list
    }
    setShowPopup(true);
    setIsWalletCheck(true); // Mark this as wallet check popup
  };

  // Regular popups like "Coming Soon" or "Next Week!"
  const handleComingSoonClick = (e: React.MouseEvent, message: string) => {
    e.preventDefault();
    if (audioRef.current) {
      audioRef.current.play();
    }
    setPopupMessage(message);
    setShowPopup(true);
    setIsWalletCheck(false); // Mark as regular popup
    setTimeout(() => setShowPopup(false), 3000);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false); // Close the overlay
  };

  return (
    <div className={styles.mobilehomepage}>
      <audio ref={audioRef} src="/growl.mp3" preload="auto" />
      <img src="/newmobile.png" alt="Mobile Map" className={styles.mobileImage} />

      <div className={styles.mapOverlay}>
        {/* Medium link */}
        <a
          href="https://medium.com/@genxdegen0x/argo-the-puppy-social-club-76b206346cf1"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.mapLink} ${styles.mediumLink}`}
        />

        {/* Twitter link */}
        <a
          href="https://x.com/argothepuppy"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.mapLink} ${styles.twitterLink}`}
        />

        {/* Discord link */}
        <a
          href="https://discord.com/invite/5KA64pKarU"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.mapLink} ${styles.discordLink}`}
        />

        {/* Eden link */}
        <a
          href="https://magiceden.us/ordinals/marketplace/argothepuppy"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.mapLink} ${styles.edenLink}`}
        />

        {/* "Coming Soon" area - Trigger the Grr! */}
        <a
          href="https://gamma.io/ordinals/collections/argo-the-puppy"
          target="_blank"
          rel="noopener noreferrer"
          
          className={`${styles.mapLink} ${styles.comingSoonLink}`}
        />

        {/* "Coming Soon" area - Trigger the Grr! */}
        <a
          href="https://www.okx.com/ul/P9re8Kf"
          target="_blank"
          rel="noopener noreferrer"
          
          className={`${styles.mapLink} ${styles.okxLink}`}
        />

        {/* "Coming Soon" area - Trigger the Grr! */}
        <a
          href="/argod2"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.mapLink} ${styles.comingSoonLink2}`}
        />

        {/* Gallery Link */}
        <a
          href="/gallery"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.mapLink} ${styles.gallerylink1}`}
        />

        {/* Gallery Link */}
        <a
          href="/argod2"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.mapLink} ${styles.argod}`}
        />

        {/* Holders Link */}
        <a
          href="/holders"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.mapLink} ${styles.holderslink1}`}
        />

        {/* Project Info button */}
        <g>
          <rect
            className={`${styles.mapLink} ${styles.projectInfoRect}`}   // CSS for positioning and styling
            onClick={() => setShowOverlay(true)}  // Open overlay on click
            onTouchStart={() => setShowOverlay(true)}  // Open overlay on touch (for mobile)
          />
        </g>
      </div>

      {showOverlay && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '95dvw',
            height: '95dvh',
            backgroundColor: 'rgba(0, 0, 0, 1)', // Semi-transparent background
            zIndex: 1001,
            display: 'flex',
            flexDirection: 'column', // Stacking sections vertically
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '10px',
            borderRadius: '20px',
            boxSizing: 'border-box',
          }}
        >
          {/* Title Section */}
          <h2
            style={{
              color: 'white',
              fontSize: '1.5rem',
              textAlign: 'center',
              marginBottom: '20px', // Adds space below the title
              marginTop: '0px',
              background: 'black'
            }}
          >
            Argo The Puppy & Achilleas The Artist!
          </h2>

          {/* Gallery and Paragraph Section */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '65%', // Allocate space for gallery and text
              backgroundColor: 'black',
              borderRadius: '10px',
              overflow: 'hidden',
              padding: '20px',
              boxSizing: 'border-box',
              marginTop: '0px'
            }}
          >
            {/* Image Gallery */}
            <div
              style={{
                width: '30%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={galleryImages[currentImageIndex]}
                alt="Gallery"
                style={{
                  maxWidth: '85%',
                  maxHeight: '85%',
                  objectFit: 'cover',
                  transition: 'all 0.5s ease-in-out', // Smooth transition
                }}
              />
            </div>

            {/* Paragraph Section */}
            <div
              style={{
                width: '70%',
                padding: '2.5px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                fontSize: '.8rem',
                overflow: 'scroll',
                color: 'white',
                textAlign: 'justify'
              }}
            >
              {/* Display paragraph based on the selected index */}
              {currentParagraphIndex === 0 ? (
                <p>
                  “Argo The Puppy” is the brainchild of Achilleas, illustrator and tattooer based in Athens Greece. Argo was designed originally for his fundraising project that supported dogs and animals during the wildfires of 2021 in Greece. Argo is already tattooed about four hundred times in La Santa Tinta, Achilleas' tattoo studio. The original sketch is based on Argo, a stray dog that Achilleas adopted in 2010 and became his faithful partner for 9 years until he passed away.

                  The earnings from those tattoos went to Dog' Voice, a non profit organisation that focuses on the protection of stray animals in Greece. Dogs' Voice was founded in 2015 with the intention to support animal welfare organisations throughout the country, to promote stray animals in responsible adoptions and to implement programs that protect animals from neglect, abuse and uncontrolled breeding. Since 2017, Dogs' Voice has been involved in crisis management regarding the protection of animals in cases of natural disasters. In 2023, Dogs' Voice presented the action of “Advocates for Animals of Greece” with the mission of protecting animals through the legal system.

                  The name Argo comes from Argos, the loyal dog of Odysseus. Argos was the one that waited patiently for Odysseus' return in Ithaca for 20 years while Odysseus and his crew were battling in the sea. He recognised him at first glance although Odysseus was disguised. He just had enough strength to drop his ears and wag his tail but could not get up to greet his master. Unable to greet his beloved dog, as this would betray who he really was, Odysseus passes by, but not without shedding a tear, and enters his hall, as Argos dies.

                  Argo The Puppy, patron of the strays!
                </p>
              ) : (
                <p>
                  Now is the time for Argo to get on the blockchain. “Argo The Puppy” is a collection of 3333 unique pieces of Argo, with really funny traits and eye catching design.

                  This will be an ordinals collection on BTC chain. Our original plan was to make a classic drop on Etherium but Elon likes his non fungible tokens on chain, so we have to abide. The exact link and date of mint will be announced on our X page and discord.

                  10% of the mint revenue will go to Dogs' Voice.

                  And now comes the sentence you have all been waiting for:

                  “There are many plans for the future, this is just the first step of our journey.”

                  The truth is that we really believe in the power of Argo. What can Argo be besides an inscription on the skin as well as an inscription on BTC? It can be a t-shirt, it can be a nice print on the wall, a plush toy... it can be a lot of things. All with a common denominator: Animal welfare.

                  We have plans for IRL merchandise and further drops, but it all really depends on the success of this collection. We think that “Argo The Puppy” should be in the wallet of every collector or degen who is an animal lover and cares about the well-being of these unfortunate souls. We humans will always be responsible for the fate of them as well as the planet as a whole.
                </p>
              )}
            </div>
          </div>

          {/* Previous and Next Buttons to switch paragraphs */}
          <div style={{
            position: 'relative',
            width: '40%',
          }}>
            <img
              src="/buttons.png"
              alt="Navigation buttons"
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
                marginLeft: '30%'
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: '35%',
                width: '35%',
                height: '45%',
                cursor: 'pointer'
              }}
              onClick={() => setCurrentParagraphIndex(0)}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: '77%',
                width: '50%',
                height: '45%',
                cursor: 'pointer'
              }}
              onClick={() => setCurrentParagraphIndex(1)}
            />
            <div
              style={{
                position: 'absolute',
                top: '52%',
                left: '38%',
                width: '75%',
                height: '50%',
                cursor: 'pointer'
              }}
              onClick={handleCloseOverlay}
            />
          </div>
        </div>

      )}

      {showPopup && (
        <div className={styles.popup} style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          backgroundColor: 'white',
          border: '2px solid black',
          borderRadius: '8px',
          zIndex: 1000,
          width: '80%', // Ensure the popup fits within mobile screen
          maxWidth: '500px', // Maximum width for larger screens
          textAlign: 'center', // Center the content
          boxSizing: 'border-box', // Ensure padding doesn't affect width
        }}>
          {!walletStatus && isWalletCheck ? (
            <>
              <input
                type="text"
                value={walletInput}
                onChange={(e) => setWalletInput(e.target.value)}
                placeholder="Enter your wallet address"
                className={styles.inputField}
                style={{
                  width: '100%', // Ensure the input fits inside the container
                  padding: '10px',
                  marginBottom: '10px',
                  fontSize: '16px',  // Prevent zoom on mobile
                  boxSizing: 'border-box', // Ensure padding is included in the element's width
                }}
              />
              <button onClick={handleWalletCheck} className={styles.checkButton} style={{ padding: '10px', width: '100%', fontSize: '20px' }}>
                Check Wallet
              </button>
            </>
          ) : (
            <>
              <p>{popupMessage}</p>
              {walletStatus && isWalletCheck && (
                <>
                  <button onClick={() => setShowPopup(false)} className={styles.closeButton} style={{ padding: '10px', width: '100%' }}>
                    Close
                  </button>
                  {tweetUrl && (
                    <a
                      href={tweetUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.tweetButton}
                      style={{
                        display: 'block',
                        marginTop: '10px',
                        backgroundColor: '#1DA1F2',
                        color: 'white',
                        textAlign: 'center',
                        padding: '10px',
                        textDecoration: 'none',
                        borderRadius: '5px',
                      }}
                    >
                      Share on X!
                    </a>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      {/* Optional overlay */}
      {showPopup && (
        <div
          className={styles.popupOverlay}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default MobileMap2;