import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './DiscordCallback.module.css';

const API_URL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === 'development' ? 'http://localhost:5008' : 'https://api.argothepuppy.org');

const DiscordCallback: React.FC = () => {
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [message, setMessage] = useState<string>('Processing your Discord login...');
  const [errorDetails, setErrorDetails] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Extract the code from URL query parameters
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
      
        if (!code) {
          setStatus('error');
          setMessage('No authorization code received from Discord');
          return;
        }
          
        console.log('Sending Discord auth code to backend...');
          
        const response = await axios.post(`${API_URL}/api/auth/discord/callback`, {
          code
        }, {
          withCredentials: true, // Important for CORS with credentials
          headers: {
            'Content-Type': 'application/json'
          }
        });
          
        const data = response.data;
          
        if (data.success) {
          // Store user data in localStorage for the app to use
          localStorage.setItem('discordUser', JSON.stringify(data.user));
            
          if (data.user.wallet_address) {
            localStorage.setItem('walletAddress', data.user.wallet_address);
          }
            
          setStatus('success');
          setMessage('Login successful! Redirecting to Argo AI...');
            
          // Set a timer to redirect to keep the success message visible briefly
          setTimeout(() => {
            navigate('/argod2');  // <-- This is the line we need to change
          }, 1500);
        }
        
        // Update it to:
        if (data.success) {
          // Store user data in localStorage for the app to use
          localStorage.setItem('discordUser', JSON.stringify(data.user));
            
          if (data.user.wallet_address) {
            localStorage.setItem('walletAddress', data.user.wallet_address);
          }
            
          setStatus('success');
          setMessage('Login successful! Redirecting to Argo AI...');
            
          // Set a timer to redirect to keep the success message visible briefly
          setTimeout(() => {
            navigate('/argod2full');  // <-- Changed to redirect to the full version
          }, 1500);
        } else {
          throw new Error('Authentication failed with no specific error');
        }
      } catch (error) {
        console.error('Error during Discord authentication:', error);
        setStatus('error');
        
        // Handle various error scenarios
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const responseData = error.response.data;
            
            if (responseData.verificationRequired) {
              setMessage('Verification Required');
              setErrorDetails('You need to verify ownership of an Argo the Puppy inscription in our Discord server first. Please join our Discord and use the /connect command.');
            } else if (responseData.error) {
              setMessage('Authentication Failed');
              setErrorDetails(responseData.error);
            } else if (error.response.status === 403) {
              setMessage('Access Denied');
              setErrorDetails('You do not have the required role to access this application. Please verify your ordinals ownership with the /connect command in our Discord server.');
            } else if (error.response.status === 404) {
              setMessage('Service Not Available');
              setErrorDetails('The authentication service could not be reached. Please try again later.');
            } else {
              setMessage('Authentication Error');
              setErrorDetails(`Server responded with error: ${error.response.status}`);
            }
          } else if (error.request) {
            setMessage('Network Error');
            setErrorDetails('Could not connect to the authentication service. Please check your internet connection and try again.');
          } else {
            setMessage('Authentication Error');
            setErrorDetails(error.message);
          }
        } else {
          setMessage('Authentication Error');
          setErrorDetails('An unexpected error occurred. Please try again later.');
        }
      }
    };

    handleCallback();
  }, [location, navigate]);

  const handleReturnHome = () => {
    navigate('/');
  };

  const handleTryAgain = () => {
    window.location.href = '/'; // Full page refresh to restart the auth flow
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.header}>
          <h1>Argo Authentication</h1>
        </div>
        
        <div className={styles.content}>
          {status === 'loading' && (
            <div className={styles.loading}>
              <div className={styles.spinner}></div>
              <p>{message}</p>
            </div>
          )}
          
          {status === 'success' && (
            <div className={styles.success}>
              <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg>
              <p>{message}</p>
            </div>
          )}
          
          {status === 'error' && (
            <div className={styles.error}>
              <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="15" y1="9" x2="9" y2="15"></line>
                <line x1="9" y1="9" x2="15" y2="15"></line>
              </svg>
              <h2>{message}</h2>
              {errorDetails && <p className={styles.errorDetails}>{errorDetails}</p>}
              <div className={styles.buttonGroup}>
                <button onClick={handleReturnHome} className={styles.button}>
                  Return to Home
                </button>
                <button onClick={handleTryAgain} className={styles.button}>
                  Try Again
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscordCallback;