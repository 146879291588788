import React from 'react';
import styles from '../styles/Home.module.css';

const AiPage: React.FC = () => {
  return (
    <div className={styles.landingContainer}>
      <div className={styles.imagecontainer}>
        <img className={styles.argoailogo} src="/argod2logo.png" alt="" />
      </div>
      <h1 className={styles.title}>Welcome to ArgoD2!</h1>
      <div className={styles.buttonContainer}>
        <a href="/argod2full" className={styles.button} target="_blank" rel="noopener noreferrer">
          <span className={styles.buttonText}>Full Version</span>
          <span className={styles.tokenGated}>(Holders)</span>
        </a>
        <a href="/argod2lite" className={styles.button} target="_blank" rel="noopener noreferrer">
         <span className={styles.buttonText}>Lite Version</span>
          <span className={styles.tokenGated}>(Public)</span>
        </a>
      </div>
    </div>
  );
};

export default AiPage;