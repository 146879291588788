import React, { useEffect, useRef } from 'react';

// Properly type the TradingView widget
declare global {
  interface Window {
    TradingView: {
      widget: new (config: {
        width: string;
        height: string;
        symbol: string;
        interval: string;
        timezone: string;
        theme: string;
        style: string;
        locale: string;
        toolbar_bg: string;
        enable_publishing: boolean;
        allow_symbol_change: boolean;
        container_id: string;
      }) => any;
    };
  }
}

interface ChartToolProps {
  symbol: string;
  assetType: 'stock' | 'crypto';
}

const ChartTool: React.FC<ChartToolProps> = ({ symbol, assetType }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    script.onload = () => initWidget();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initWidget = () => {
    if (containerRef.current && window.TradingView) {
      new window.TradingView.widget({
        width: '95%',
        height: '95%',
        symbol: `${assetType === 'crypto' ? 'COINBASE:' : ''}${symbol}`,
        interval: 'D',
        timezone: 'Etc/UTC',
        theme: 'dark',
        style: '1',
        locale: 'en',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: containerRef.current.id,
      });
    }
  };

  return <div id="tradingview_widget" ref={containerRef} />;
};

export default ChartTool;