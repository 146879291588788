import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getAddress, GetAddressOptions, AddressPurpose, BitcoinNetworkType } from 'sats-connect';
import axios from 'axios';
import styles from './WalletVerification.module.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.argothepuppy.org';

const WalletVerification: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const location = useLocation();
  const verificationStarted = useRef(false);
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    console.log('[WalletVerification] Component mounted.');
    if (verificationStarted.current) {
      console.log('[WalletVerification] Verification already started; skipping duplicate call.');
      return;
    }
    
    if (!token) {
      setError('Invalid verification link. No token provided.');
      return;
    }
    
    verificationStarted.current = true;
    console.log('[WalletVerification] useEffect triggered with token:', token?.substring(0, 10) + '...');
  }, [token]);

  const connectWallet = async (): Promise<string | null> => {
    console.log('[connectWallet] Initiating wallet connection.');
    try {
      return new Promise((resolve, reject) => {
        const getAddressOptions: GetAddressOptions = {
          payload: {
            purposes: [AddressPurpose.Ordinals],
            message: 'Connect to verify ownership',
            network: { type: BitcoinNetworkType.Mainnet }
          },
          onFinish: (response) => {
            console.log('[connectWallet] Received wallet connection response:', response);
            const ordinalsAddress = response.addresses.find(
              addr => addr.purpose === AddressPurpose.Ordinals
            );
            if (ordinalsAddress) {
              console.log('[connectWallet] Ordinals address found:', ordinalsAddress.address);
              resolve(ordinalsAddress.address);
            } else {
              console.error('[connectWallet] No ordinals address found in response.');
              resolve(null);
            }
          },
          onCancel: () => {
            console.warn('[connectWallet] Wallet connection cancelled by user.');
            reject(new Error('User cancelled wallet connection'));
          }
        };
        console.log('[connectWallet] Calling getAddress with options:', getAddressOptions);
        getAddress(getAddressOptions);
      });
    } catch (error) {
      console.error('[connectWallet] Error in connectWallet function:', error);
      return null;
    }
  };

  const verifyWallet = async () => {
    try {
      if (!token) {
        console.error('[verifyWallet] No token provided.');
        setError('Invalid verification link');
        return;
      }

      setIsVerifying(true);

      console.log('[verifyWallet] Calling connectWallet()...');
      const address = await connectWallet();
      console.log('[verifyWallet] Wallet address obtained:', address);
      
      if (!address) {
        console.error('[verifyWallet] Failed to obtain wallet address.');
        setError('Failed to connect wallet');
        return;
      }

      setWalletAddress(address);
      
      console.log('[verifyWallet] Preparing API request to:', `${API_URL}/api/verify`);
      console.log('[verifyWallet] Request payload:', { token, wallet_address: address });

      const response = await axios.post(`${API_URL}/api/verify`, {
        token,
        wallet_address: address
      });
      
      console.log('[verifyWallet] API response status:', response.status);
      console.log('[verifyWallet] API response data:', response.data);

      if (response.data.success) {
        console.log('[verifyWallet] Verification succeeded.');
        setIsSuccess(true);
        setTimeout(() => {
          console.log('[verifyWallet] Closing window after success.');
          window.close();
        }, 3000);
      } else {
        console.error('[verifyWallet] Verification failed with response:', response.data);
        setError(response.data.error || 'Verification failed. Make sure you own an Argo the Puppy ordinal.');
      }
    } catch (err: any) {
      console.error('[verifyWallet] Error during verification process:', err);
      if (err.response) {
        console.error('[verifyWallet] Error response status:', err.response.status);
        console.error('[verifyWallet] Error response data:', JSON.stringify(err.response.data));
      }
      setError(err.response?.data?.error || 'Error during verification');
    } finally {
      console.log('[verifyWallet] Verification process completed.');
      setIsVerifying(false);
    }
  };

  return (
    <div className={styles.container} style={{
      minHeight: '100vh',
      backgroundColor: '#1a1a1a',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px'
    }}>
      <div className={styles.verificationBox} style={{
        backgroundColor: '#2a2a2a',
        padding: '30px',
        borderRadius: '12px',
        textAlign: 'center'
      }}>
        <h1>Argo Wallet Verification</h1>
        
        {isSuccess && (
          <div>
            <h2>Verification Successful!</h2>
            <p>Your wallet has been verified and your Discord role has been assigned.</p>
            <p>This window will close automatically in 3 seconds...</p>
            <button onClick={() => window.close()}>Close Now</button>
          </div>
        )}
        
        {isVerifying && (
          <div>
            <p>Verifying your wallet...</p>
            <div className={styles.loader}></div>
          </div>
        )}
        
        {error && (
          <div>
            <p style={{ color: 'red' }}>{error}</p>
            <button onClick={() => window.close()}>Close</button>
          </div>
        )}
        
        {!isVerifying && !error && !isSuccess && !walletAddress && (
          <div>
            <p>Please connect your Bitcoin wallet to verify ownership of an Argo the Puppy ordinal.</p>
            <p>This will check if you own any Argo the Puppy ordinals and assign you the appropriate role in Discord.</p>
            <button 
              onClick={verifyWallet} 
              className={styles.connectButton}
              style={{
                backgroundColor: '#f6851b',
                color: 'white',
                padding: '12px 24px',
                border: 'none',
                borderRadius: '6px',
                fontSize: '16px',
                fontWeight: 'bold',
                cursor: 'pointer',
                marginTop: '20px'
              }}
            >
              Connect Wallet
            </button>
          </div>
        )}
        
        {!isVerifying && !error && !isSuccess && walletAddress && (
          <div>
            <p>Wallet connected: {walletAddress.substring(0, 6)}...{walletAddress.substring(walletAddress.length - 4)}</p>
            <p>Checking for valid Argo the Puppy ordinals...</p>
            <div className={styles.loader}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletVerification;