import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import axios from 'axios';
import styles from './ChatBack.module.css';
import ChartTool from './ChartTool';
import DexScreenerEmbed from './DexScreenerEmbed';
import WatchlistPanel from './Watchlist';
import WalletModal from './WalletModal';
import Papa from 'papaparse';
import { request, AddressPurpose, BitcoinNetworkType, RpcErrorCode } from 'sats-connect';
import ConversationLibrary from './ConversationLibrary';
import CombinedLoginModal from './CombinedLoginModal';
import { useNavigate } from 'react-router-dom';
import BitcoinPrice from './BitcoinPrice';
import BitcoinPriceSimulated from './BitcoinPriceSimulated';




const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5008';


interface Inscription {
    inscriptionId: string;
}

interface Metadata {
    id: string;
    name: string;
    image: string;
    attributes: { trait_type: string; value: string }[];
    inscriptionId: string;
}

interface Message {
    agent: string;
    message: string;
    timestamp?: string;
    imageUrl?: string;
    uploadedImage?: string;
    imageAnalysis?: string;
    b64_json?: string;
    isBlobUrl?: boolean;

}

interface MessageType {
    agent: string;
    message: string;
    imageUrl?: string;
    isBlobUrl?: boolean;

    // Add any other properties your messages have
}

interface AgentOutput {
    id: string;
    name: string;
    output: string;
    timestamp: string;
}

interface InscriptionResponse {
    id: string;
    number: number;
    address: string | null;
    genesis_address: string | null;
    genesis_block_height: number;
    genesis_block_hash: string;
    genesis_tx_id: string;
    genesis_fee: string;
    genesis_timestamp: number;
    tx_id: string;
    location: string;
}

interface PaginatedInscriptionsResponse {
    limit: number;
    offset: number;
    total: number;
    results: InscriptionResponse[];
}

interface MatchedIdEntry {
    inscription_id: string;
    json_file: string;
    image_file: string;
}

interface BtcAccount {
    address: string;
    addressType: "p2tr" | "p2wpkh" | "p2sh" | "p2pkh";
    publicKey: string;
    purpose: "payment" | "ordinals";
}

const inscriptionIdsFilePath = '/inscription_ids.json';
const matchedIdsFilePath = '/matchedids.csv';
const metadataBasePath = '/metadata/';
const argosImageBasePath = '/argoimages/';


const ChatComponent: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isConversationActive, setIsConversationActive] = useState(false);
    const [isReviewerEnabled, setIsReviewerEnabled] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isThinking, setIsThinking] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    const [showChartTool, setShowChartTool] = useState(false);
    const [showLibrary, setShowLibrary] = useState(true);
    const [showDexScreener, setShowDexScreener] = useState(false);
    const [dexScreenerContract, setDexScreenerContract] = useState('');
    const [dexScreenerChain, setDexScreenerChain] = useState('ethereum');
    const [showMemeChartPopup, setShowMemeChartPopup] = useState(false);
    const [memeChartContract, setMemeChartContract] = useState('');
    const [memeChartChain, setMemeChartChain] = useState('ethereum');
    const [showDexScreenerPopup, setShowDexScreenerPopup] = useState(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [showTokenDataPopup, setShowTokenDataPopup] = useState(false);
    const [tokenAddress, setTokenAddress] = useState('');
    const [currentRequest, setCurrentRequest] = useState<AbortController | null>(null);
    const [voiceEnabled, setVoiceEnabled] = useState(false);
    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
    const [chartSymbol, setChartSymbol] = useState<string>('BTCUSD');
    const [chartType, setChartType] = useState<'stock' | 'crypto'>('crypto');
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
    const [connectedWalletType, setConnectedWalletType] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);
    const [connected, setConnected] = useState(false);
    const [ordinalAddress, setOrdinalAddress] = useState('');
    const [filteredInscriptions, setFilteredInscriptions] = useState<Inscription[]>([]);
    const [ownedNFTs, setOwnedNFTs] = useState<Metadata[]>([]);
    const [currentConversationId, setCurrentConversationId] = useState<string | null>(null);
    const [messageHistory, setMessageHistory] = useState<Array<{ role: string; content: string }>>([]);
    const [isCurrentAgentA, setIsCurrentAgentA] = useState<boolean>(true);
    const [isAgentMode, setIsAgentMode] = useState(false);
    const [agentConversation, setAgentConversation] = useState<Array<{ agent: string; message: string }>>([]);
    const [isAgentTaskComplete, setIsAgentTaskComplete] = useState(false);
    const [currentAgent, setCurrentAgent] = useState<'A' | 'B'>('A');
    const [agentOutputs, setAgentOutputs] = useState<AgentOutput[]>([]);
    const [normalInput, setNormalInput] = useState('');
    const [agentInput, setAgentInput] = useState('');
    const [isAgentThinking, setIsAgentThinking] = useState(false);
    const [selectedAgentOutput, setSelectedAgentOutput] = useState<string | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isGeneratingImage, setIsGeneratingImage] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isAnalyzingImage, setIsAnalyzingImage] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showWatchlist, setShowWatchlist] = useState(true);
    const [imgB64, setImgB64] = useState<string>('');
    const [focusedPanel, setFocusedPanel] = useState<'library' | 'chat' | 'watchlist' | null>(null);
    // Add this with your other state declarations
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [agentTurnCount, setAgentTurnCount] = useState<number>(0);
    const [isAwaitingClarification, setIsAwaitingClarification] = useState<boolean>(false);
    const [initialUserPrompt, setInitialUserPrompt] = useState<string>('');
    const [clarificationContext, setClarificationContext] = useState<string>('');
    const [isFetchMode, setIsFetchMode] = useState<boolean>(false);
    const [fetchPrompt, setFetchPrompt] = useState<string>("");
    const MAX_AGENT_TURNS = 5;

    const axiosInstance = axios.create({
        timeout: 600000, // 10 minutes
        withCredentials: true,
    });
    // Mobile menu state
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [activePanel, setActivePanel] = useState<'chat' | 'library' | 'watchlist'>('chat');
    const dataUrl = `data:image/png;base64,${imgB64}`;
    useEffect(() => {
        const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed
        if (isMobile) {
            setShowLibrary(false);
            setShowWatchlist(false);
        }
    }, []);

    useEffect(() => {
        if (connected && ordinalAddress && connectedWalletType) {
            console.log('Wallet connected, fetching Argos...');
            fetchInscriptionsWithRetry();
        }
    }, [connected, ordinalAddress, connectedWalletType]);

    // Add this with your other useEffect hooks
    useEffect(() => {
        // Check if user is already logged in via Discord
        const discordUser = localStorage.getItem('discordUser');
        const walletAddress = localStorage.getItem('walletAddress');

        if (discordUser && walletAddress) {
            try {
                const userData = JSON.parse(discordUser);
                console.log('Found Discord user in localStorage:', userData);
                // Connect using the stored wallet address
                handleWalletConnect(walletAddress, 'discord');
            } catch (error) {
                console.error('Error parsing Discord user data:', error);
                // If there's an error, show the login modal
                setIsLoginModalOpen(true);
            }
        } else {
            // No stored credentials, show the login modal
            setIsLoginModalOpen(true);
        }
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleImageUploadClick = () => {
        fileInputRef.current?.click();
    };



    const handleOptionClick = (action: () => void) => {
        action();
        setIsDropdownOpen(false);
    };



    const fetchInscriptionIds = async () => {
        try {
            const response = await fetch(inscriptionIdsFilePath);
            const data: string[] = await response.json();

            return data;
        } catch (err) {
            console.error('Error fetching inscription_ids.json:', err);
            return [];
        }
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const disconnectWallet = async () => {
        try {
            console.log('Attempting to disconnect wallet...');
            const response = await request('wallet_renouncePermissions', undefined);
            if (response.status === 'success') {
                setOrdinalAddress('');
                setConnected(false);
                setOwnedNFTs([]);
                setError(null);
                console.log('Wallet disconnected successfully');
            } else {
                throw new Error('Failed to disconnect wallet');
            }
        } catch (err) {
            setError('Error disconnecting wallet: ' + (err instanceof Error ? err.message : String(err)));
            console.error('Error in disconnectWallet:', err);
        }
    };

    const handleGoFetch = () => {
        setIsFetchMode(true);
        displayMessage('ArgoD2', "What news would you like me to fetch?");
        setIsDropdownOpen(false);
    };

    // Find your existing handleWalletConnect function and update it like this:
    const handleWalletConnect = (address: string, walletType: string) => {
        console.log(`Attempting to connect wallet. Type: ${walletType}, Address: ${address}`);
        setOrdinalAddress(address);
        setConnected(true);
        setConnectedWalletType(walletType);
        console.log(`Wallet connected successfully. Type: ${walletType}, Address: ${address}`);

        // Close the combined login modal after successful connection
        closeLoginModal();

        // Don't try to create a conversation here - the state hasn't updated yet
        // Instead, store the address for later use
        localStorage.setItem('walletAddress', address);
    };

    // This code should be added to your ChatDis.tsx component
    // Add this new useEffect hook near your other useEffect hooks
    useEffect(() => {
        // Only run this when ordinalAddress becomes available (after login) and if no conversation exists
        if (ordinalAddress && connected && !currentConversationId) {
            console.log('Wallet address available, creating initial conversation');
            (async () => {
                try {
                    setIsLoading(true);
                    // Using the address directly from state now that it's available
                    const newConversationId = await createInitialConversation();
                    if (newConversationId) {
                        console.log(`Created initial conversation: ${newConversationId}`);
                        // The important part - set this in state
                        setCurrentConversationId(newConversationId);
                        // Clear messages for the new conversation
                        setMessages([]);
                        setMessageHistory([]);
                    } else {
                        console.error('Failed to create initial conversation after login');
                    }
                } catch (error) {
                    console.error('Error creating initial conversation:', error);
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [ordinalAddress, connected, currentConversationId]);

    // Add this helper function to create the initial conversation
    const createInitialConversation = async (): Promise<string | null> => {
        try {
            if (!ordinalAddress) {
                console.error('No wallet connected');
                return null;
            }

            const newConversationId = Date.now().toString();
            console.log('Creating initial conversation with ID:', newConversationId);

            // Create a formatted date string for the conversation name
            const now = new Date();
            const formattedDate = `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear().toString().slice(2)} ${formatTime(now)}`;

            try {
                // First check if a conversation with this ID already exists
                const checkResponse = await axios.get(
                    `${API_URL}/api/conversations/${newConversationId}/status?wallet_address=${ordinalAddress}`,
                    { withCredentials: true }
                );

                if (checkResponse.data.exists) {
                    console.log('Conversation already exists:', newConversationId);
                    return newConversationId;
                }
            } catch (checkError) {
                // Ignore errors here - we'll try to create a new one anyway
                console.warn('Error checking conversation:', checkError);
            }

            const response = await axios.post(
                `${API_URL}/api/conversations`,
                {
                    wallet_address: ordinalAddress,
                    conversation_id: newConversationId,
                    name: formattedDate // Use formatted date instead of "New Conversation"
                },
                { withCredentials: true }
            );

            if (response.data?.success) {
                console.log('Successfully created initial conversation:', newConversationId);
                return newConversationId;
            } else {
                console.error('Failed to create conversation:', response.data);
                return null;
            }
        } catch (error) {
            console.error('Error creating initial conversation:', error);
            return null;
        }
    };

    const getFullImageUrl = (imageUrl: string): string => {
        if (!imageUrl) return '';

        // If it's already a full URL or data URI, return as is
        if (imageUrl.startsWith('http') || imageUrl.startsWith('data:')) {
            return imageUrl;
        }

        // Handle relative URLs properly
        const apiUrl = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;
        const relativeUrl = imageUrl.startsWith('/') ? imageUrl : `/${imageUrl}`;

        return `${apiUrl}${relativeUrl}`;
    };

    const handleViewAgentOutput = (outputContent: string) => {
        console.log("Setting selectedAgentOutput:", outputContent);
        setSelectedAgentOutput(outputContent);
    };

    // Find your existing handleDisconnect function and update it like this:
    const handleDisconnect = () => {
        setOrdinalAddress('');
        setConnected(false);
        setConnectedWalletType(null);
        setOwnedNFTs([]);
        setError(null);

        // Clear Discord login data
        localStorage.removeItem('discordUser');
        localStorage.removeItem('walletAddress');

        // Show the login modal again
        setIsLoginModalOpen(true);
    };
    const openLoginModal = () => {
        setIsLoginModalOpen(true);
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    const closeAgentModal = () => {
        setIsAgentMode(false);
        setAgentConversation([]);
        setIsAgentTaskComplete(false);
        setCurrentAgent('A');
        setIsThinking(false);
        setAgentInput('');
    };

    function ImageRenderer({ message }: { message: any }) {
        const isBlobUrl = message.isBlobUrl;
        const imageUrl = message.imageUrl;

        return (
            <div className={styles.imageContainer}>
                <img
                    src={isBlobUrl ? imageUrl : getFullImageUrl(imageUrl)}
                    alt="Generated artwork"
                    className={styles.generatedImage}
                    onClick={() => setSelectedImageUrl(isBlobUrl ? imageUrl : getFullImageUrl(imageUrl))}
                    style={{ cursor: "pointer" }}
                    onError={(e) => {
                        console.error('Error loading image:', imageUrl, 'Full URL:', isBlobUrl ? imageUrl : getFullImageUrl(imageUrl));
                    }}
                />
            </div>
        );
    }



    const handleImageUpload = async (file: File) => {
        try {
            setIsAnalyzingImage(true);

            console.log(`Original image: ${file.name}, type: ${file.type}, size: ${file.size / 1024} KB`);

            // Compress the image before uploading
            let imageToUpload = file;
            try {
                imageToUpload = await compressImage(file);
                console.log(`Compressed image size: ${imageToUpload.size / 1024} KB (${Math.round((imageToUpload.size / file.size) * 100)}% of original)`);
            } catch (compressionError) {
                console.warn('Failed to compress image:', compressionError);
            }

            // Create form data and append necessary fields
            const formData = new FormData();
            formData.append('image', imageToUpload);
            if (ordinalAddress) {
                formData.append('wallet_address', ordinalAddress);
            }
            if (currentConversationId) {
                formData.append('conversation_id', currentConversationId);
            }

            // Display the uploaded image and insert the AI message immediately after
            const imageUrl = URL.createObjectURL(imageToUpload);
            displayMessage('User', 'I uploaded an image for analysis:', imageUrl);
            displayMessage('ArgoD2', 'Let me take a look at that image for you');

            console.log('Sending request to analyze-image endpoint...', API_URL);

            const response = await axios.post(`${API_URL}/api/analyze-image`, formData, {
                withCredentials: false,
                timeout: 120000,
                headers: {
                    'Accept': 'application/json'
                },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log(`Upload progress: ${percentage}%`);
                    }
                }
            });

            console.log('Received response from server:', response.status);

            if (response.data && response.data.analysis) {
                console.log('Analysis received from server, displaying in chat');
                displayMessage('ArgoD2', response.data.analysis);
            } else {
                throw new Error('No analysis returned from server');
            }
        } catch (error) {
            console.error('Error in handleImageUpload:', error);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    console.error('Server error response:', error.response.status, error.response.data);
                    setError(`Server error (${error.response.status}): ${error.response.data.error || 'Unknown server error'}`);
                } else if (error.request) {
                    console.error('No response received from server');
                    setError('Could not connect to the server. Please check your internet connection and try again.');
                } else {
                    console.error('Error setting up request:', error.message);
                    setError(`Request setup error: ${error.message}`);
                }
            } else {
                setError('Failed to analyze image: ' + (error instanceof Error ? error.message : 'Unknown error'));
            }
        } finally {
            setIsAnalyzingImage(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };



    const fetchAgentOutputs = async () => {
        try {
            const response = await fetch(`${API_URL}/api/agent-outputs/${ordinalAddress}`);
            if (response.ok) {
                const data = await response.json();
                const formattedData = data.map((item: any) => ({
                    id: item.id,
                    name: item.name,
                    output: item.output || "",
                    timestamp: item.timestamp,
                }));
                setAgentOutputs(formattedData);
            } else {
                console.error('Failed to fetch agent outputs:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching agent outputs:', error);
        }
    };

    const testImageUpload = async (file: File) => {
        try {
            console.log("Testing image upload with simplified endpoint...");

            const formData = new FormData();
            formData.append('image', file);

            const response = await axios.post(
                `${API_URL}/api/test-upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            console.log("Test upload response:", response.data);
            return response.data.success;
        } catch (error) {
            console.error("Test upload failed:", error);
            return false;
        }
    };

    const fetchInscriptions = async (): Promise<void> => {
        setIsLoading(true);
        if (!connected || !ordinalAddress) {
            console.error('Attempted to fetch inscriptions while disconnected');
            setError('Wallet is not connected. Please connect your wallet and try again.');
            setIsLoading(false);
            return;
        }

        try {
            console.log(`Fetching inscriptions for ${connectedWalletType}...`);
            let fetchedInscriptions: Inscription[] = [];

            if (connectedWalletType === 'xverse') {
                try {
                    const response = await request('ord_getInscriptions', {
                        offset: 0,
                        limit: 100,
                    });

                    console.log('ord_getInscriptions response:', response);

                    if (response.status === 'success') {
                        fetchedInscriptions = response.result.inscriptions;
                    } else if (response.error.code === RpcErrorCode.ACCESS_DENIED) {
                        console.log('Access denied. Requesting permissions...');

                        const permissionResponse = await request('wallet_requestPermissions', undefined);

                        if (permissionResponse.status === 'success') {
                            console.log('Permissions granted. Retrying fetchInscriptions...');
                            setIsLoading(false);
                            return fetchInscriptions();
                        } else {
                            throw new Error('User declined to grant permissions for fetching inscriptions');
                        }
                    } else {
                        throw new Error(`Failed to fetch inscriptions: ${response.error.message}`);
                    }
                } catch (err) {
                    console.error('Error during fetching inscriptions:', err);
                    throw err;
                }
            } else if (connectedWalletType === 'phantom' || connectedWalletType === 'magiceden') {
                fetchedInscriptions = await fetchInscriptionsFromAPI(ordinalAddress, 0, 60);
            } else if (connectedWalletType === 'discord') {
                // For Discord logins, we can skip fetching inscriptions from a wallet
                // because we've already verified they have access through the Discord authentication
                console.log('Discord login detected - skipping wallet inscription check');

                // Create a placeholder inscription to satisfy the flow
                fetchedInscriptions = [{ inscriptionId: 'discord-verified' }];

                // Skip the filtering and just set access to true
                setOwnedNFTs([{
                    id: 'discord-verified',
                    name: 'Discord Verified Access',
                    image: '', // You could use a placeholder image here if desired
                    attributes: [],
                    inscriptionId: 'discord-verified'
                }]);

                // Skip the actual fetch since Discord users are pre-verified
                setIsLoading(false);
                return;
            } else {
                throw new Error('Unknown wallet type');
            }

            console.log('Fetched inscriptions from wallet: ', fetchedInscriptions);

            const filteredInscriptions = await filterInscriptions(fetchedInscriptions);
            const idToFileMapping = await fetchMatchedIds();
            await loadNFTs(idToFileMapping, filteredInscriptions);
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
            setError(`Error fetching inscriptions: ${errorMessage}`);
            console.error('Error in fetchInscriptions:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const loadConversation = async (conversationId: string, messages: any[]) => {
        try {
            console.log('Raw messages from database:', messages);
            setSelectedAgentOutput(null);
            setMessageHistory([]);
            setCurrentConversationId(conversationId);

            const formattedMessages = messages.reduce((acc: Message[], msg) => {
                if (!msg.content && !msg.image_url) {
                    return acc;
                }

                if (msg.image_url) {
                    acc.push({
                        agent: msg.role === 'user' ? 'user' : 'Assistant',
                        message: "Here's your drawing:",
                        imageUrl: msg.image_url
                    });
                } else if (!msg.content.includes('Generated image:')) {
                    acc.push({
                        agent: msg.role === 'user' ? 'user' : 'Assistant',
                        message: msg.content
                    });
                }
                return acc;
            }, []);

            console.log('Formatted messages:', formattedMessages);

            const historyMessages = messages.map(msg => ({
                role: msg.role,
                content: msg.content
            }));

            setMessageHistory(historyMessages);
            setMessages(formattedMessages);
            setIsConversationActive(true);
        } catch (error) {
            console.error('Error loading conversation:', error);
            setError('Failed to load conversation');
        }
    };

    const fetchInscriptionsFromAPI = async (address: string, offset: number, limit: number): Promise<Inscription[]> => {
        try {
            const response = await fetch(`https://api.hiro.so/ordinals/v1/inscriptions?address=${address}&limit=${limit}&offset=${offset}`);
            if (!response.ok) {
                throw new Error('Failed to fetch inscriptions from Hiro API');
            }
            const data: PaginatedInscriptionsResponse = await response.json();
            return data.results.map((item: InscriptionResponse) => ({
                inscriptionId: item.id,
            }));
        } catch (error) {
            console.error('Error fetching inscriptions from Hiro API:', error);
            throw error;
        }
    };

    const fetchInscriptionsWithRetry = async (retries = 3) => {
        for (let i = 0; i < retries; i++) {
            try {
                await fetchInscriptions();
                return;
            } catch (error) {
                console.error(`Attempt ${i + 1} failed:`, error);
                if (i === retries - 1) {
                    setError(`Failed to fetch inscriptions after ${retries} attempts. Please try again later.`);
                } else {
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            }
        }
    };

    const fetchMatchedIds = async () => {
        return new Promise<{ [key: string]: { json_file: string; image_file: string } }>((resolve, reject) => {
            Papa.parse(matchedIdsFilePath, {
                download: true,
                header: true,
                complete: (results) => {
                    const mapping: { [key: string]: { json_file: string; image_file: string } } = {};
                    results.data.forEach((row: unknown) => {
                        const matchedEntry = row as MatchedIdEntry;
                        mapping[matchedEntry.inscription_id] = {
                            json_file: matchedEntry.json_file,
                            image_file: matchedEntry.image_file,
                        };
                    });
                    console.log("Fetched matched IDs: ", mapping);
                    resolve(mapping);
                },
                error: (error) => {
                    console.error('Error parsing matchedids.csv:', error);
                    reject(error);
                },
            });
        });
    };

    const filterInscriptions = async (fetchedInscriptions: Inscription[]) => {
        const validInscriptionIds = await fetchInscriptionIds();
        const filtered = fetchedInscriptions.filter(inscription =>
            validInscriptionIds.includes(inscription.inscriptionId)
        );
        console.log("Filtered inscriptions: ", filtered);
        setFilteredInscriptions(filtered);
        return filtered;
    };

    const loadNFTs = async (idToFileMap: { [key: string]: { json_file: string; image_file: string } }, inscriptions: Inscription[]) => {
        const nfts: Metadata[] = [];

        for (const inscription of inscriptions) {
            const matchedFiles = idToFileMap[inscription.inscriptionId];
            if (matchedFiles) {
                console.log(`Matched Inscription ID ${inscription.inscriptionId}:`, matchedFiles);

                try {
                    const response = await fetch(`${metadataBasePath}${matchedFiles.json_file.split('/').pop()}`);
                    if (!response.ok) {
                        console.error(`Failed to fetch metadata: ${matchedFiles.json_file}`);
                        continue;
                    }
                    const metadata = await response.json();
                    nfts.push({
                        ...metadata,
                        image: `${argosImageBasePath}${matchedFiles.image_file.split('/').pop()}`,
                        inscriptionId: inscription.inscriptionId,
                    });
                    console.log("Fetched NFT metadata:", metadata);
                } catch (err) {
                    console.error(`Error fetching metadata for ${inscription.inscriptionId}:`, err);
                }
            } else {
                console.error(`No match for inscription ID: ${inscription.inscriptionId}`);
            }
        }

        setOwnedNFTs(nfts);
        console.log("Final owned Argos: ", nfts);
    };

    const connectWallet = async () => {
        try {
            console.log('Attempting to connect wallet...');
            const response = await request('getAccounts', {
                purposes: ['ordinals' as AddressPurpose],
                message: 'Connect to view your ordinals',
            });

            if (response.status === 'success') {
                handleSuccessfulConnection(response);
            } else if (response.error.code === RpcErrorCode.ACCESS_DENIED) {
                console.log('Access denied. Requesting permissions...');
                const permissionResponse = await request('wallet_requestPermissions', undefined);

                if (permissionResponse.status === 'success') {
                    console.log('Permissions granted. Retrying getAccounts...');
                    const retryResponse = await request('getAccounts', {
                        purposes: ['ordinals' as AddressPurpose],
                        message: 'Connect to view your ordinals',
                    });

                    if (retryResponse.status === 'success') {
                        handleSuccessfulConnection(retryResponse);
                    } else {
                        throw new Error('Failed to get accounts after granting permissions');
                    }
                } else {
                    throw new Error('User declined to grant permissions');
                }
            } else {
                throw new Error('Failed to connect wallet');
            }
        } catch (err) {
            setError('Error connecting wallet: ' + (err instanceof Error ? err.message : String(err)));
            console.error('Error in connectWallet:', err);
        }
    };


    const debugImageUrl = (imageUrl: string): void => {
        console.log('----------------------------------------');
        console.log('Image URL Debug:');
        console.log('Original URL:', imageUrl);
        console.log('Full URL after processing:', getFullImageUrl(imageUrl));
        console.log('API_URL value:', API_URL);
        console.log('----------------------------------------');
    };

    const handleSuccessfulConnection = (response: any) => {
        const ordinalsAddressItem = response.result[0];
        if (ordinalsAddressItem) {
            console.log('Wallet connected successfully:', ordinalsAddressItem);
            setOrdinalAddress(ordinalsAddressItem.address);
            setConnected(true);

            if (response.walletType === 'xverse') {
                setConnectedWalletType('xverse');
            } else if (response.walletType === 'magiceden') {
                setConnectedWalletType('magiceden');
            } else {
                setConnectedWalletType('unknown');
            }
        } else {
            setError('No ordinals address found');
        }
    };

    const handleNewChat = async () => {
        try {
            setMessages([]);
            setMessageHistory([]);
            setSelectedAgentOutput(null);
            setIsConversationActive(false);
            setIsThinking(false);
            setError(null);

            const newConversationId = await newChat();

            if (!newConversationId) {
                throw new Error('Failed to create a new conversation.');
            }

            console.log(`New conversation started with ID: ${newConversationId}`);
        } catch (error) {
            console.error('Error in handleNewChat:', error);
            setError('Failed to start a new chat. Please try again.');
        }
    };

    async function downloadImageClientSide(imageUrl: string): Promise<void> {
        try {
            console.log('Attempting to download image from:', imageUrl);

            // Fetch the image directly from the browser
            const response = await fetch(imageUrl);

            if (!response.ok) {
                throw new Error(`Failed to fetch image: ${response.statusText}`);
            }

            // Get the image as a blob
            const blob = await response.blob();
            console.log('Image fetched successfully, size:', blob.size);

            // Create a local object URL
            const localUrl = URL.createObjectURL(blob);

            // Create a temporary link and trigger the download
            const link = document.createElement('a');
            link.href = localUrl;
            link.download = 'generated_image.png';
            document.body.appendChild(link);

            console.log('Triggering download...');
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(localUrl);

            console.log('Download complete');
        } catch (err) {
            console.error('Error downloading image:', err);
            alert('Failed to download image. You can try right-clicking and saving manually.');
        }
    }

    async function downloadImageFromUrl(imageUrl: string): Promise<void> {
        try {
            // Fetch the image from the URL
            const response = await fetch('/api/download-image', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ imageUrl }),
            });

            if (!response.ok) {
                throw new Error(`Failed to download image: ${response.statusText}`);
            }

            // Convert the response to a blob
            const blob = await response.blob();

            // Create a local object URL
            const localUrl = URL.createObjectURL(blob);

            // Create a temporary link and trigger the download
            const link = document.createElement('a');
            link.href = localUrl;
            link.download = 'generated_image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Clean up the object URL
            URL.revokeObjectURL(localUrl);
        } catch (err) {
            console.error('Error downloading image:', err);
            alert('Failed to download. Please try opening in a new tab.');
        }
    }
    // Frontend (TypeScript) code:
    async function saveImage(b64_json: string): Promise<void> {
        try {
            const response = await fetch('/api/proxy-image', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ b64_json }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch image via proxy');
            }
            const blob = await response.blob();
            const localUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = localUrl;
            link.download = 'generated_image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(localUrl);
        } catch (err) {
            console.error('Error saving image:', err);
            alert('Failed to save. Please open in new tab or right-click to save.');
        }
    }

    const formatMessage = (message: string) => {
        // First handle code blocks using your existing implementation
        const codeBlocksProcessed = message.split(/(```[\s\S]*?```)/);

        return codeBlocksProcessed.map((part, index) => {
            if (part.startsWith('```') && part.endsWith('```')) {
                const code = part.slice(3, -3);
                const language = code.split('\n')[0];
                const codeContent = code.split('\n').slice(1).join('\n');
                return (
                    <div key={index} className={styles.codeBlock}>
                        <div className={styles.codeHeader}>
                            <span className={styles.language}>{language}</span>
                            <button className={styles.copyBtn} onClick={() => copyToClipboard(codeContent)}>Copy</button>
                        </div>
                        <pre><code className={`language-${language}`}>{codeContent}</code></pre>
                    </div>
                );
            } else {
                // Process markdown headings (# and ##)
                let processedText = part.replace(/^# (.*?)$/gm, '<h1>$1</h1>');
                processedText = processedText.replace(/^## (.*?)$/gm, '<h2>$1</h2>');

                // Process markdown links [text](url)
                processedText = processedText.replace(/\[([^\]]+)\]\(([^)]+)\)/g,
                    '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');

                // Handle newlines
                processedText = processedText.replace(/\n/g, '<br>');

                return <p key={index} dangerouslySetInnerHTML={{ __html: processedText }} />;
            }
        });
    };

    const speakMessage = async (text: string) => {
        try {
            if (currentAudio) {
                stopSpeaking();
            }

            const response = await axios.post(`${API_URL}/api/text-to-speech`, {
                text: text
            }, {
                responseType: 'blob'
            });

            const audioBlob = response.data;
            const url = window.URL.createObjectURL(audioBlob);
            const audio = new Audio(url);

            setCurrentAudio(audio);

            audio.onended = () => {
                setCurrentAudio(null);
            };

            audio.play();

        } catch (error) {
            console.error('Error in text-to-speech:', error);
        }
    };

    const stopSpeaking = () => {
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            setCurrentAudio(null);
        }
    };

    const toggleTokenDataPopup = () => {
        setShowTokenDataPopup(!showTokenDataPopup);
        setTokenAddress('');
    };

    const handleOpenChart = (symbol: string, type: 'stock' | 'crypto') => {
        if (showDexScreener) {
            setShowDexScreener(false);
        }
        setShowChartTool(true);
    };

    const handleOpenDexScreener = (contractAddress: string, chain: string) => {
        if (showChartTool) {
            setShowChartTool(false);
        }
        setShowDexScreener(true);
        setDexScreenerContract(contractAddress);
        setDexScreenerChain(chain);
    };

    const handleFetchTokenData = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!tokenAddress) return;

        try {
            setIsThinking(true);
            setShowTokenDataPopup(false);
            const response = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`);
            const tokenData = response.data.pairs[0];

            if (tokenData) {
                const formattedData = formatTokenData(tokenData);
                displayMessage('System', formattedData);
            } else {
                displayMessage('System', 'No data found for the given token address.');
            }
        } catch (error) {
            console.error("Error fetching token data:", error);
            setError("Error fetching token data. Please try again.");
        } finally {
            setIsThinking(false);
            setTokenAddress('');
        }
    };



    const formatTokenData = (tokenData: any) => `
    <p><strong>Token Data:</strong></p>
    <p>Name: ${tokenData.baseToken.name}</p>
    <p>Price: $${parseFloat(tokenData.priceUsd).toFixed(13)}</p>
    <p>24h Volume: $${tokenData.volume.h24.toFixed(2)}</p>
    <p>Liquidity (USD): $${tokenData.liquidity.usd.toFixed(2)}</p>
    <p>24h Price Change: ${tokenData.priceChange.h24.toFixed(2)}%</p>
    <p>Market Cap: $${tokenData.marketCap ? tokenData.marketCap.toFixed(2) : 'N/A'}</p>
`;

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Code copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const stopConversation = async () => {
        if (!isConversationActive) return;

        if (currentRequest) {
            currentRequest.abort();
        }

        stopSpeaking();

        setIsConversationActive(false);
        setIsStopped(true);
        setIsThinking(false);

        try {
            const response = await axios.post(`${API_URL}/api/stop`);
        } catch (error) {
            console.error('Error stopping conversation:', error);
            setError('Failed to stop conversation');
        }
    };

    const newChat = async () => {
        try {
            if (!ordinalAddress) {
                console.error('No wallet connected');
                return null;
            }

            const newConversationId = Date.now().toString();
            console.log('Creating new conversation with ID:', newConversationId);

            // Create a formatted date string for the conversation name using the local timezone
            const now = new Date();
            const formattedDate = `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear().toString().slice(2)} ${formatTime(now)}`;

            const response = await axios.post(`${API_URL}/api/conversations`, {
                wallet_address: ordinalAddress,
                conversation_id: newConversationId,
                name: formattedDate // Use formatted date instead of "New Conversation"
            });

            if (response.data?.success) {
                console.log('Successfully created conversation:', newConversationId);

                setCurrentConversationId(newConversationId);
                setMessages([]);
                setMessageHistory([]);
                setInputMessage('');
                setError(null);
                setIsCurrentAgentA(true);
                setIsConversationActive(false);

                setRefreshTrigger(prev => prev + 1);

                loadConversation(newConversationId, []);

                return newConversationId;
            } else {
                console.error('Failed to create conversation:', response.data);
                return null;
            }
        } catch (error) {
            console.error('Error creating new chat:', error);
            setError('Failed to create new chat');
            return null;
        }
    };


    // This should be placed with other utility functions in the file
    const formatTime = (date: Date): string => {
        // Create a new date object to avoid modifying the original
        const adjustedDate = new Date(date);

        // Directly subtract 5 hours to adjust for the timezone difference
        adjustedDate.setHours(adjustedDate.getHours() - 5);

        // Format the time
        let hours = adjustedDate.getHours();
        const minutes = adjustedDate.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        return `${hours}:${minutes}${ampm}`;
    };

    const downloadImageCrossDevice = async (message: MessageType): Promise<void> => {
        try {
            if (!message.imageUrl) {
                console.error('No image URL found');
                return;
            }

            // Extract the filename from the URL path
            const urlParts = message.imageUrl.split('/');
            const filename = urlParts[urlParts.length - 1];

            console.log(`Downloading image: ${filename}`);

            // Use our raw download endpoint
            window.open(`${API_URL}/api/download-raw-image/${filename}`, '_blank');
        } catch (error) {
            console.error('Error downloading image:', error);
            setError('Failed to download image');
        }
    };


    // Updated displayMessage function
    const displayMessage = (agent: string, message: string, imageUrl?: string) => {
        let displayAgent = agent;
        if (!isReviewerEnabled && agent === 'System') {
            displayAgent = 'Argo';
        }

        // Log what we're adding to messages
        console.log(`Displaying message from ${displayAgent}${imageUrl ? ' with image' : ''}`);
        if (imageUrl) {
            console.log(`Image URL type: ${typeof imageUrl}`);
            console.log(`Image URL starts with: ${imageUrl.substring(0, 20)}...`);
        }

        // Ensure we're not adding invalid image URLs
        if (imageUrl && (
            !imageUrl.startsWith('data:image/') &&
            !imageUrl.startsWith('http://') &&
            !imageUrl.startsWith('https://') &&
            !imageUrl.startsWith('blob:')
        )) {
            console.warn('Invalid image URL format:', imageUrl.substring(0, 50));
            // Try to fix the URL by adding data:image/png;base64, prefix if it looks like base64
            if (/^[A-Za-z0-9+/=]+$/.test(imageUrl.substring(0, 20))) {
                imageUrl = `data:image/png;base64,${imageUrl}`;
                console.log('Fixed image URL to:', imageUrl.substring(0, 50));
            }
        }

        setMessages(prev => [...prev, {
            agent: displayAgent,
            message,
            imageUrl
        }]);
    };

    // Add this helper function to save fetch searches to the conversation database

    // Update the handleFetchModeMessage function to save results to conversation history
    const handleFetchModeMessage = async (userQuery: string): Promise<void> => {
        try {
            setError(null);
            setIsThinking(true);

            // Ensure we have a conversation ID
            let conversationId = currentConversationId;
            if (!conversationId) {
                conversationId = await newChat();
                if (!conversationId) {
                    throw new Error('Failed to create new conversation');
                }
                setCurrentConversationId(conversationId);
            }

            // Display user's query
            displayMessage('User', userQuery);

            // Informative message while fetching
            const fetchingMessage = "I'm fetching the latest news and information about this topic... 🔍";
            displayMessage('ArgoD2', fetchingMessage);

            // Use your existing /api/search endpoint directly
            const response = await axios.post(`${API_URL}/api/search`, {
                query: userQuery
            });

            // Remove the "fetching" message
            setMessages(prevMessages => prevMessages.filter(msg =>
                !(msg.agent === 'ArgoD2' && msg.message === fetchingMessage)
            ));

            if (response.data && response.data.summary) {
                // If the endpoint already provides a summary, use it
                const summary = response.data.summary;

                // Save the user's query to conversation history
                if (conversationId && ordinalAddress) {
                    try {
                        await axios.post(`${API_URL}/api/messages`, {
                            conversation_id: conversationId,
                            role: 'user',
                            content: userQuery,
                            wallet_address: ordinalAddress
                        }, { withCredentials: true });
                    } catch (error) {
                        console.error('Error saving user query to conversation:', error);
                    }
                }

                // Display the summary
                displayMessage('ArgoD2', summary);

                // Save the AI's response to conversation history
                if (conversationId && ordinalAddress) {
                    try {
                        await axios.post(`${API_URL}/api/messages`, {
                            conversation_id: conversationId,
                            role: 'assistant',
                            content: summary,
                            wallet_address: ordinalAddress
                        }, { withCredentials: true });
                    } catch (error) {
                        console.error('Error saving search results to conversation:', error);
                    }
                }
            } else if (response.data && response.data.results && response.data.results.length > 0) {
                // If no summary but we have results, get the raw results and process them
                const rawResults = response.data.results;

                // Make another request to get a summary of these results
                const summaryResponse = await axios.post(`${API_URL}/api/search`, {
                    query: userQuery,
                    results: rawResults
                });

                const summary = summaryResponse.data.summary || "I found some information, but couldn't create a proper summary.";

                // Display the summary
                displayMessage('ArgoD2', summary);

                // Save to conversation history
                if (conversationId && ordinalAddress) {
                    try {
                        await axios.post(`${API_URL}/api/messages`, {
                            conversation_id: conversationId,
                            role: 'assistant',
                            content: summary,
                            wallet_address: ordinalAddress
                        }, { withCredentials: true });
                    } catch (error) {
                        console.error('Error saving search results to conversation:', error);
                    }
                }
            } else {
                // No results found
                const noResultsMessage = "I couldn't find any recent information about that topic. Would you like to try a different search term?";
                displayMessage('ArgoD2', noResultsMessage);

                // Save the empty results message
                if (conversationId && ordinalAddress) {
                    try {
                        await axios.post(`${API_URL}/api/messages`, {
                            conversation_id: conversationId,
                            role: 'assistant',
                            content: noResultsMessage,
                            wallet_address: ordinalAddress
                        }, { withCredentials: true });
                    } catch (error) {
                        console.error('Error saving no results message to conversation:', error);
                    }
                }
            }

            // Ensure conversation is marked as active
            setIsConversationActive(true);

            // Exit fetch mode
            setIsFetchMode(false);

            // Optional: voice output if enabled
            if (voiceEnabled && response.data && (response.data.summary || (response.data.results && response.data.results.length > 0))) {
                const summaryForVoice = "I've found some information on that topic. You can review the results in our chat.";
                await speakMessage(summaryForVoice);
            }
        } catch (error) {
            console.error('Error in fetch mode:', error);
            setError('Failed to fetch news information. Please try again.');
            setIsFetchMode(false);
        } finally {
            setIsThinking(false);
        }
    };


    // Function to handle sending messages
    const handleSendMessage = async (): Promise<void> => {
        if (!normalInput.trim()) return;

        const messageToSend = normalInput;
        setNormalInput('');

        // Handle special fetch mode
        if (isFetchMode) {
            await handleFetchModeMessage(messageToSend);
            return;
        }

        // Only set up abort controller for non-drawing messages
        let abortController: AbortController | null = null;
        if (!messageToSend.toLowerCase().startsWith('draw')) {
            if (currentRequest) {
                currentRequest.abort();
            }
            abortController = new AbortController();
            setCurrentRequest(abortController);
        }

        try {
            setError(null);
            setIsThinking(true);

            let conversationId = currentConversationId;
            if (!conversationId) {
                console.log('No conversation ID found, creating new chat');
                conversationId = await newChat();
                if (!conversationId) {
                    throw new Error('Failed to create new conversation');
                }
            }

            displayMessage('User', messageToSend);

            if (messageToSend.toLowerCase().startsWith('draw')) {
                // Let user know we're generating
                displayMessage('ArgoD2', "I'm drawing that for you now...");

                try {
                    // Use the URL-based endpoint
                    const response = await axios.post(
                        `${API_URL}/api/generate-image-url`,
                        {
                            prompt: messageToSend,
                            wallet_address: ordinalAddress,
                            conversation_id: conversationId
                        }
                    );

                    if (response.data && response.data.imageUrl) {
                        // Display a message first
                        displayMessage('ArgoD2', "Here's your drawing:");

                        // Then display the image using imageUrl property to trigger the image container
                        // This uses your existing image display logic instead of HTML content
                        const imageUrl = response.data.imageUrl;

                        // Update the messages array with the new image
                        // Notice we're setting imageUrl property instead of putting the image in the message
                        setMessages(prevMessages => [
                            ...prevMessages,
                            {
                                agent: 'ArgoD2',
                                message: '',  // Empty message since we're displaying an image
                                imageUrl: imageUrl
                            }
                        ]);

                        // Log the revised prompt if available
                        if (response.data.revisedPrompt) {
                            console.log('Revised prompt:', response.data.revisedPrompt);
                        }
                    } else {
                        throw new Error('No image URL returned');
                    }
                } catch (error) {
                    console.error('Error generating image:', error);
                    displayMessage('ArgoD2', "I'm sorry, I couldn't create that drawing. Please try again with a different description.");
                }
            } else {
                // First, check if this is a query that might need real-time information
                const needsRealtimeInfo = await checkIfRealtimeInfoNeeded(messageToSend, messages);

                if (needsRealtimeInfo) {
                    // Show a temporary message to inform the user we're gathering info
                    const infoMessage = "I'm gathering the latest information for you... 🔍";
                    displayMessage('ArgoD2', infoMessage);

                    // Fetch real-time information using API call
                    const searchResults = await fetchRealtimeInfo(messageToSend);

                    // Remove the temporary message
                    setMessages(prevMessages => prevMessages.filter(msg =>
                        !(msg.agent === 'ArgoD2' && msg.message === infoMessage)
                    ));

                    // Include the search results in the message to the API
                    const responseWithInfo = await axios.post(`${API_URL}/api/send_message`, {
                        message: messageToSend,
                        isInitial: !isConversationActive,
                        wallet_address: ordinalAddress,
                        conversation_id: conversationId,
                        messageHistory: messages.map(msg => ({
                            role: msg.agent.toLowerCase() === 'user' ? 'user' : 'assistant',
                            content: msg.message,
                        })),
                        realtimeInfo: searchResults
                    }, {
                        withCredentials: true,
                        signal: abortController?.signal
                    });

                    if (responseWithInfo.data.message) {
                        displayMessage('ArgoD2', responseWithInfo.data.message);
                        setIsConversationActive(true);

                        if (voiceEnabled) {
                            await speakMessage(responseWithInfo.data.message);
                        }
                    }
                } else {
                    // Regular message handling without real-time info
                    const response = await axios.post(`${API_URL}/api/send_message`, {
                        message: messageToSend,
                        isInitial: !isConversationActive,
                        wallet_address: ordinalAddress,
                        conversation_id: conversationId,
                        messageHistory: messages.map(msg => ({
                            role: msg.agent.toLowerCase() === 'user' ? 'user' : 'assistant',
                            content: msg.message,
                        }))
                    }, {
                        withCredentials: true,
                        signal: abortController?.signal
                    });

                    if (response.data.message) {
                        displayMessage('ArgoD2', response.data.message);
                        setIsConversationActive(true);

                        if (voiceEnabled) {
                            await speakMessage(response.data.message);
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
            if (axios.isAxiosError(error)) {
                if (error.response?.data?.error) {
                    setError(`Error: ${error.response.data.error}`);
                } else if (error.code === 'ERR_CANCELED') {
                    setError('Request was canceled');
                } else if (error.code === 'ERR_NETWORK') {
                    setError('Network error. Please check your connection and try again.');
                } else {
                    setError(`Failed to send message: ${error.message}`);
                }
            } else {
                setError('Failed to send message');
            }
        } finally {
            if (abortController && abortController === currentRequest) {
                setCurrentRequest(null);
            }
            setIsThinking(false);
        }
    };

    // Function to check if a message needs real-time information
    const checkIfRealtimeInfoNeeded = async (message: string, conversationHistory: Message[]): Promise<boolean> => {
        //  try {
        // Prepare message history for the check
        //    const formattedHistory = conversationHistory.map(msg => ({
        //      role: msg.agent.toLowerCase() === 'user' ? 'user' : 'assistant',
        //    content: msg.message
        //  }));

        // Call the API to determine if real-time info is needed
        //  const response = await axios.post(`${API_URL}/api/check-realtime-info`, {
        //    message,
        //    messageHistory: formattedHistory
        // });

        //  return response.data.needsRealtimeInfo;
        //  } catch (error) {
        //    console.error('Error checking if real-time info is needed:', error);
        // Default to false if there's an error
        return false;
        // }
    };

    // Function to fetch real-time information
    const fetchRealtimeInfo = async (query: string): Promise<string[]> => {
        try {
            const response = await axios.post(`${API_URL}/api/search`, {
                query
            });

            return response.data.results;
        } catch (error) {
            console.error('Error fetching real-time information:', error);
            return [];
        }
    };

    const handleAgentMessage = async () => {
        try {
            console.log('Starting agent message handler');
            console.log('Current Agent:', currentAgent);
            console.log('Conversation Length:', agentConversation.length);
            console.log('Is Thinking:', isThinking);

            setIsThinking(true);
            const isFirstMessage = agentConversation.length === 0;

            const messageToSend = isFirstMessage ? agentInput : agentConversation[agentConversation.length - 1].message;
            console.log('Message to send:', messageToSend);

            const response = await fetch(`${API_URL}/api/agent-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: messageToSend,
                    isAgentA: currentAgent === 'A',
                    isFirstMessage
                }),
            });

            const data = await response.json();
            console.log('Received response:', data);

            const newConversation = [...agentConversation, {
                agent: currentAgent,
                message: data.response
            }];
            setAgentConversation(newConversation);

            if (isFirstMessage) {
                setAgentInput('');
            }

            if (data.response.includes('TASK COMPLETE') && currentAgent === 'B') {
                console.log('Task complete detected');
                setIsAgentTaskComplete(true);
                if (newConversation.length >= 2) {
                    const finalOutput = newConversation[newConversation.length - 2].message;
                    console.log('Final output to save:', finalOutput);
                    await saveAgentOutput(finalOutput);
                }
            } else {
                const nextAgent = currentAgent === 'A' ? 'B' : 'A';
                console.log('Switching to agent:', nextAgent);

                await new Promise(resolve => setTimeout(resolve, 500));
                setCurrentAgent(nextAgent);

                if (currentAgent === 'A' && !isFirstMessage) {
                    console.log('Scheduling next agent response');
                    setTimeout(() => {
                        handleAgentMessage();
                    }, 1000);
                }
            }
        } catch (error) {
            console.error('Error in agent message:', error);
            setError('Error processing agent message');
        } finally {
            setIsThinking(false);
        }
    };

    const handleInitialAgentMessage = async () => {
        try {
            setIsAgentThinking(true);
            setInitialUserPrompt(agentInput);

            // First check if we need clarity
            const clarityCheckResponse = await fetch(`${API_URL}/api/check-clarity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: agentInput
                }),
            });

            const clarityData = await clarityCheckResponse.json();

            if (clarityData.needsClarity) {
                // Agent A is asking for clarity
                setAgentConversation([{
                    agent: 'A',
                    message: clarityData.clarityQuestion
                }]);
                setAgentInput('');
                setIsAwaitingClarification(true);
                setAgentTurnCount(0);
                // Important: we return early here to wait for clarification input
                return;
            } else {
                // Proceed with normal flow if no clarity needed
                const response = await fetch(`${API_URL}/api/agent-message`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        message: agentInput,
                        isAgentA: true,
                        isFirstMessage: true,
                        turnCount: 0
                    }),
                });

                const data = await response.json();

                setAgentConversation([{
                    agent: 'A',
                    message: data.response
                }]);
                setAgentInput('');
                setCurrentAgent('B');
                setAgentTurnCount(1);

                setTimeout(() => {
                    handleAgentBResponse(data.response);
                }, 1000);
            }
        } catch (error) {
            console.error('Error in initial agent message:', error);
            setError('Error processing agent message');
        } finally {
            setIsAgentThinking(false);
        }
    };

    const handleClarificationResponse = async () => {
        try {
            setIsAgentThinking(true);

            // Combine initial prompt with clarification
            setClarificationContext(agentInput);
            const combinedPrompt = `Initial Request: ${initialUserPrompt}\n\nClarification: ${agentInput}`;

            const response = await fetch(`${API_URL}/api/agent-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: combinedPrompt,
                    isAgentA: true,
                    isFirstMessage: true,
                    withClarification: true,
                    turnCount: 0
                }),
            });

            const data = await response.json();

            // Now completely reset the conversation with Agent A's response to the clarified prompt
            setAgentConversation([
                {
                    agent: 'A',
                    message: data.response
                }
            ]);

            setAgentInput('');
            setCurrentAgent('B');
            setIsAwaitingClarification(false);
            setAgentTurnCount(1);

            // Now proceed with Agent B's response
            setTimeout(() => {
                handleAgentBResponse(data.response);
            }, 1000);
        } catch (error) {
            console.error('Error processing clarification:', error);
            setError('Error processing clarification');
        } finally {
            setIsAgentThinking(false);
        }
    };

    const handleAgentBResponse = async (previousMessage: string) => {
        try {
            setIsAgentThinking(true);

            // Check if we've reached max turns
            if (agentTurnCount >= MAX_AGENT_TURNS) {
                // Force completion if we reach max turns
                setAgentConversation(prev => [...prev, {
                    agent: 'B',
                    message: "TASK COMPLETE - Maximum conversation turns reached."
                }]);

                setIsAgentTaskComplete(true);
                return;
            }

            const response = await fetch(`${API_URL}/api/agent-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: previousMessage,
                    isAgentA: false,
                    isFirstMessage: false,
                    turnCount: agentTurnCount
                }),
            });

            const data = await response.json();

            // Update the conversation state
            setAgentConversation(prev => [...prev, {
                agent: 'B',
                message: data.response
            }]);

            if (data.response.includes('TASK COMPLETE')) {
                setIsAgentTaskComplete(true);

                // Don't attempt to find or save Agent A messages here
                // The save will happen later when the Save button is clicked
            } else {
                setCurrentAgent('A');
                setAgentTurnCount(count => count + 1);
                setTimeout(() => {
                    handleAgentAResponse(data.response);
                }, 1000);
            }
        } catch (error) {
            console.log('Agent B response processed');
        } finally {
            setIsAgentThinking(false);
        }
    }



    const handlePaste = async (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const items = e.clipboardData?.items;
        if (!items) return;

        const itemsArray = Array.from(items);

        for (const item of itemsArray) {
            if (item.type.indexOf('image') === 0) {
                e.preventDefault();
                const file = item.getAsFile();
                if (file) {
                    await handleImageUpload(file);
                    break;
                }
            }
        }
    };

    const compressImage = async (file: File): Promise<File> => {
        return new Promise((resolve, reject) => {
            // Skip compression for small images (less than 500KB)
            if (file.size < 512000) {
                console.log('Image already small enough, skipping compression');
                resolve(file);
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target?.result as string;
                img.onload = () => {
                    // Determine reasonable dimensions based on the original size
                    // Limit to max 1200px width/height while maintaining aspect ratio
                    const MAX_DIMENSION = 1200;
                    let width = img.width;
                    let height = img.height;

                    if (width > height && width > MAX_DIMENSION) {
                        height = Math.round((height * MAX_DIMENSION) / width);
                        width = MAX_DIMENSION;
                    } else if (height > MAX_DIMENSION) {
                        width = Math.round((width * MAX_DIMENSION) / height);
                        height = MAX_DIMENSION;
                    }

                    // Create canvas and draw resized image
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    if (!ctx) {
                        reject(new Error('Failed to get canvas context'));
                        return;
                    }

                    // Draw image with smoother quality
                    ctx.imageSmoothingQuality = 'high';
                    ctx.drawImage(img, 0, 0, width, height);

                    // Convert to blob with compression
                    // Adjust the quality here - 0.5 to 0.7 is usually a good balance
                    // Lower number = smaller file but lower quality
                    const COMPRESSION_QUALITY = 0.5;

                    canvas.toBlob(
                        (blob) => {
                            if (!blob) {
                                reject(new Error('Failed to compress image'));
                                return;
                            }

                            // Create a new file from the blob
                            const compressedFile = new File([blob], file.name, {
                                type: 'image/jpeg', // Always convert to JPEG for best compression
                                lastModified: Date.now()
                            });

                            console.log(`Original: ${file.size / 1024}KB, Compressed: ${compressedFile.size / 1024}KB, Reduction: ${Math.round((1 - compressedFile.size / file.size) * 100)}%`);

                            resolve(compressedFile);
                        },
                        'image/jpeg',
                        COMPRESSION_QUALITY
                    );
                };

                img.onerror = () => {
                    reject(new Error('Failed to load image for compression'));
                };
            };

            reader.onerror = () => {
                reject(new Error('Failed to read file for compression'));
            };
        });
    };

    // Modify handleFileInputChange to use compression
    const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        if (!file.type.startsWith('image/')) {
            setError('Please upload an image file');
            return;
        }

        try {
            const compressedFile = await compressImage(file);
            await handleImageUpload(compressedFile);
        } catch (error) {
            console.error('Error processing file:', error);
            setError('Error processing file');
        }
    };

    // Frontend (TypeScript) code:
    function downloadBase64Image(b64: string): void {
        const binary = atob(b64);
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
            bytes[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'generated_image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }



    const handleAgentAResponse = async (previousMessage: string) => {
        try {
            setIsAgentThinking(true);

            // Check if we've reached max turns
            if (agentTurnCount >= MAX_AGENT_TURNS) {
                // Force completion at max turns
                // Get the latest conversation state
                const currentConversation = agentConversation;
                const lastResponse = currentConversation[currentConversation.length - 1].message;
                await saveAgentOutput(lastResponse);

                // Use spread operator to ensure we're not losing previous messages
                setAgentConversation(prev => [...prev, {
                    agent: 'A',
                    message: "This is my final response as we've reached the maximum number of turns."
                }]);

                setIsAgentTaskComplete(true);
                return;
            }

            // Log current conversation state for debugging
            console.log('Current agentConversation before A response:', agentConversation);

            const response = await fetch(`${API_URL}/api/agent-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: previousMessage,
                    isAgentA: true,
                    isFirstMessage: false,
                    turnCount: agentTurnCount
                }),
            });

            const data = await response.json();

            // Important: Use the function form of setState to ensure we're working with the latest state
            setAgentConversation(prevConversation => {
                console.log('Setting new agentConversation with previous length:', prevConversation.length);
                const newConversation = [...prevConversation, {
                    agent: 'A',
                    message: data.response
                }];
                console.log('New conversation length:', newConversation.length);
                return newConversation;
            });

            setCurrentAgent('B');
            setAgentTurnCount(count => count + 1);

            setTimeout(() => {
                handleAgentBResponse(data.response);
            }, 1000);
        } catch (error) {
            console.error('Error in Agent A response:', error);
            setError('Error processing agent message');
        } finally {
            setIsAgentThinking(false);
        }
    };

    const saveAgentOutput = async (finalOutput: string) => {
        try {
            if (!ordinalAddress) return;
            console.log('Attempting to save agent output:', finalOutput);

            const response = await fetch(`${API_URL}/api/save-agent-output`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    wallet_address: ordinalAddress,
                    output: finalOutput,
                    name: `Agent Output - ${new Date().toLocaleDateString()}`
                }),
            });

            if (!response.ok) {
                console.error('Failed to save agent output:', response.statusText);
            } else {
                console.log('Agent output saved successfully');
                fetchAgentOutputs();
            }
        } catch (error) {
            console.error('Error saving agent output:', error);
        }
    };

    const toggleMemeChart = () => {
        setShowMemeChartPopup(true);
        if (showChartTool) setShowChartTool(false);
        if (showDexScreener) setShowDexScreener(false);
    };

    const toggleReviewerMode = async () => {
        try {
            setIsReviewerEnabled(prev => !prev);
            const newConversationId = await newChat();

            if (newConversationId) {
                console.log('New conversation created with ID:', newConversationId);

                setCurrentConversationId(newConversationId);
                setMessages([]);
                setMessageHistory([]);
                setIsCurrentAgentA(true);
                setIsConversationActive(false);
                setIsStopped(false);
                setError(null);
            } else {
                console.error('Failed to create new conversation on reviewer mode toggle');
            }
        } catch (error) {
            console.error('Error toggling reviewer mode:', error);
            setError('Failed to toggle reviewer mode');
        }
    };

    async function downloadImage(imageUrl: string): Promise<void> {
        try {
            const response = await fetch('/api/proxy-image-url', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ imageUrl }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch image via proxy');
            }

            const blob = await response.blob();
            const localUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = localUrl;
            link.download = 'generated_image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(localUrl);
        } catch (err) {
            console.error('Error downloading image:', err);
            alert('Failed to save. Please open in new tab or right-click to save.');
        }
    }

    const onSelectConversation = async (conversationId: string, messages: any[]) => {
        if (conversationId) {
            setCurrentConversationId(conversationId);
            setMessages(messages);
        } else {
            setCurrentConversationId(null);
            setMessages([]);
        }
    };

    const saveLastAgentMessage = async () => {
        if (!ordinalAddress || !agentConversation.length) {
            return;
        }

        try {
            // Find the last message from Agent A, which should be the final output
            const lastAgentAMessage = [...agentConversation]
                .filter(msg => msg.agent === 'A')
                .pop();

            // If we can't find an Agent A message, just proceed without errors
            if (lastAgentAMessage) {
                const outputContent = lastAgentAMessage.message;

                console.log('Saving Agent A output...');

                const response = await fetch(`${API_URL}/api/save-agent-output`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        wallet_address: ordinalAddress,
                        output: outputContent,
                        name: `Agent Output - ${new Date().toLocaleDateString()}`
                    }),
                });

                if (response.ok) {
                    console.log('Agent output saved successfully');
                }
            }

            // Always clean up the agent UI regardless of save success
            setIsAgentMode(false);
            setAgentConversation([]);
            setIsAgentTaskComplete(false);
            setCurrentAgent('A');
            setIsAgentThinking(false);
            setAgentInput('');
            setAgentTurnCount(0);

            await fetchAgentOutputs();
            setRefreshTrigger(prev => prev + 1);

            if (currentConversationId) {
                onSelectConversation(currentConversationId, []);
            }
        } catch (error) {
            // Silently handle any errors
            console.log('Operation completed');

            // Still clean up the UI
            setIsAgentMode(false);
            setAgentConversation([]);
            setIsAgentTaskComplete(false);
            setCurrentAgent('A');
            setIsAgentThinking(false);
            setAgentInput('');
            setAgentTurnCount(0);
        }
    }

    const handleMemeChartSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (memeChartContract) {
            setShowMemeChartPopup(false);
            setShowDexScreener(true);
            setDexScreenerContract(memeChartContract);
            setDexScreenerChain(memeChartChain);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && normalInput.trim()) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const toggleChartTool = () => {
        setShowChartTool(!showChartTool);
        if (!showChartTool) {
            setChartSymbol('BTCUSD');
            setChartType('crypto');
        }
        if (showDexScreener) {
            setShowDexScreener(false);
        }
    };

    const toggleLibrary = () => {
        setShowLibrary(!showLibrary);
    };

    const toggleDexScreener = () => {
        setShowDexScreenerPopup(true);
        if (showChartTool) setShowChartTool(false);
        if (showMemeChartPopup) setShowMemeChartPopup(false);
    };

    const handleDexScreenerSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (dexScreenerContract) {
            setShowDexScreenerPopup(false);
            setShowDexScreener(true);
        }
    };

    return (
        <div className={styles.pageContainer}>
            {!connected ? (
                <div className={styles.connectPrompt}>
                    <button onClick={openLoginModal} className={styles.connectButton}>
                        Login to Access
                    </button>
                    <p className={styles.connectButton2}>Login with Discord or connect your wallet to access Argo AI.</p>
                </div>
            ) : (ownedNFTs.length > 0 || connectedWalletType === 'discord') ? (
                <>
                    <div className={styles.mainContent}>
                        {/* Conversation Library */}
                        <div className={`${styles.libraryContainer} ${!showLibrary ? styles.collapsed : ''}`}>
                            {showLibrary && (
                                <ConversationLibrary
                                    show={true}
                                    walletAddress={ordinalAddress}
                                    onSelectConversation={loadConversation}
                                    onClose={() => setShowLibrary(false)}
                                    agentOutputs={agentOutputs}
                                    onViewOutput={handleViewAgentOutput}
                                    refreshTrigger={refreshTrigger}
                                    onToggleVisibility={(isVisible) => setShowLibrary(isVisible)}
                                />
                            )}
                        </div>

                        {/* Chat */}
                        <div className={styles.chatContainer}>
                            <div className={styles.buttonContainer} ref={dropdownRef}>
                                <button onClick={() => handleOptionClick(handleNewChat)} className={styles.functionBtn}>
                                    New Chat
                                </button>
                                <h2 className={styles.headerbtn}>ArgoD2</h2>
                                <BitcoinPrice />

                                {isDropdownOpen && (
                                    <div className={styles.dropdownMenu}>
                                        <button
                                            onClick={() => handleOptionClick(() => setShowLibrary(!showLibrary))}
                                            className={styles.dropdownItem}
                                        >
                                            {showLibrary ? 'Hide Library' : 'Show Library'}
                                        </button>
                                        <button
                                            onClick={() => handleOptionClick(() => setShowWatchlist(!showWatchlist))}
                                            className={styles.dropdownItem}
                                        >
                                            {showWatchlist ? 'Hide Watchlist' : 'Show Watchlist'}
                                        </button>
                                        <button
                                            onClick={() => handleOptionClick(() => setIsAgentMode(true))}
                                            className={styles.dropdownItem}
                                        >
                                            Agents
                                        </button>
                                        <button onClick={() => handleOptionClick(toggleChartTool)} className={styles.dropdownItem}>
                                            Chart Tool
                                        </button>
                                        {/* <button onClick={() => handleOptionClick(handleGoFetch)}className={styles.dropdownItem}>Go Fetch</button> */}

                                        
                                        <button
                                            onClick={() => handleOptionClick(() => setVoiceEnabled(!voiceEnabled))}
                                            className={styles.dropdownItem}
                                        >
                                            Speech Mode {voiceEnabled ? 'Off' : 'On'}
                                        </button>
                                        <button onClick={stopConversation} className={styles.dropdownItem}>
                                            Stop Speech Output
                                        </button>
                                        <button
                                            onClick={() => fileInputRef.current?.click()}
                                            className={styles.dropdownItem}
                                            disabled={isAnalyzingImage}
                                        >
                                            {isAnalyzingImage ? '...' : 'Upload Image'}
                                        </button>
                                        <button onClick={() => handleOptionClick(handleDisconnect)} className={styles.dropdownItem}>
                                            Disconnect Wallet
                                        </button>

                                    </div>
                                )}
                            </div>
                            <div className={styles.contentContainer}>
                                {selectedAgentOutput && (
                                    <div className={`${styles.message} ${styles.agentOutput}`}>
                                        <strong>Agent Output:</strong>
                                        <div>{formatMessage(selectedAgentOutput)}</div>
                                    </div>
                                )}
                                <div className={styles.messagesContainer} ref={chatContainerRef}>
                                    {messages.map((msg, index) => (
                                        <div
                                            key={index}
                                            className={`${styles.message} ${styles[msg.agent.toLowerCase().replace(' ', '-')]}`
                                            }>
                                            <strong>{msg.agent}:</strong>
                                            {msg.message && formatMessage(msg.message)}

                                            {msg.imageUrl && (
                                                <div className={styles.imageContainer}>
                                                    <img
                                                        src={msg.imageUrl}
                                                        alt="Generated artwork"
                                                        className={styles.generatedImage}
                                                        onClick={() => setSelectedImageUrl(msg.imageUrl || null)}
                                                        style={{ cursor: 'pointer' }}
                                                        onError={(e) => {
                                                            console.error('Image failed to load:', e);
                                                            e.currentTarget.style.display = 'none';
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                    {selectedImageUrl && (
                                        <div className={styles.imageModal} onClick={() => setSelectedImageUrl(null)}>
                                            <img
                                                src={selectedImageUrl}
                                                alt="Enlarged artwork"
                                                className={styles.enlargedImage}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                            <button className={styles.closeButton} onClick={() => setSelectedImageUrl(null)}>
                                                ×
                                            </button>
                                        </div>
                                    )}

                                    {isThinking && (
                                        <div className={`${styles.message} ${styles.thinking}`}>
                                            <strong>Thinking</strong>
                                            <span className={styles.dotAnimation}>
                                                <span>.</span><span>.</span><span>.</span>
                                            </span>
                                        </div>
                                    )}
                                    {error && <div className={styles.errorMessage}>{error}</div>}
                                </div>

                                {showChartTool && (
                                    <div className={styles.chartToolContainer}>
                                        <ChartTool symbol={chartSymbol} assetType={chartType} />
                                    </div>
                                )}
                                {showDexScreener && dexScreenerContract && (
                                    <div className={styles.dexScreenerContainer}>
                                        <DexScreenerEmbed
                                            contractAddress={dexScreenerContract}
                                            chain={dexScreenerChain}
                                            onClose={() => setShowDexScreener(false)}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.inputContainer}>
                                <textarea
                                    value={normalInput}
                                    onChange={(e) => setNormalInput(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    onPaste={handlePaste}
                                    placeholder="Enter topic here or paste an image..."
                                    className={styles.messageInput}
                                    inputMode="text"  // Add this
                                    autoComplete="off" // Add this
                                />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileInputChange}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                />

                                <button onClick={handleSendMessage} className={styles.actionButton}>
                                    {isConversationActive ? 'Send' : 'Start'}
                                </button>
                                <button onClick={toggleDropdown} className={styles.actionButton}>
                                    {isDropdownOpen ? 'Close' : 'Options'}
                                </button>

                            </div>
                        </div>

                        {/* Watchlist */}
                        <div className={`${styles.watchlistContainer} ${!showWatchlist ? styles.collapsed : ''}`}>
                            {showWatchlist && (
                                <WatchlistPanel
                                    onOpenDexScreener={handleOpenDexScreener}
                                    ordinalAddress={ordinalAddress}
                                    onClose={() => setShowWatchlist(false)}
                                />
                            )}
                        </div>
                    </div>

                    {/* Agent Modal */}
                    {isAgentMode && (
                        <div className={styles.agentModal}>
                            <div className={styles.agentModalContent}>
                                <button
                                    className={styles.closeButton}
                                    onClick={() => {
                                        setIsAgentMode(false);
                                        setAgentConversation([]);
                                        setIsAgentTaskComplete(false);
                                        setCurrentAgent('A');
                                        setIsAgentThinking(false);
                                        setAgentInput('');
                                    }}
                                >
                                    ×
                                </button>
                                <div className={styles.agentMessages}>
                                    {agentConversation.map((msg, index) => (
                                        <div key={index}
                                            className={isAwaitingClarification && index === 0 ? styles.clarificationRequest : styles[`agent${msg.agent}`]}
                                        >
                                            <strong>
                                                {isAwaitingClarification && index === 0
                                                    ? 'Clarification Request:'
                                                    : msg.agent === 'A' ? 'Agent A:' : 'Agent B:'}
                                            </strong>
                                            <div className={styles.messageContent}>{msg.message}</div>
                                        </div>
                                    ))}
                                    {isAgentThinking && <div className={styles.thinking}>Thinking...</div>}
                                </div>
                                {!isAgentTaskComplete && (agentConversation.length === 0 || isAwaitingClarification) && (
                                    <div className={styles.agentInput}>
                                        <input
                                            type="text"
                                            value={agentInput}
                                            onChange={(e) => setAgentInput(e.target.value)}
                                            placeholder={isAwaitingClarification ? "Provide clarification..." : "Enter your task..."}
                                            disabled={isAgentThinking}
                                            onKeyPress={(e) =>
                                                e.key === 'Enter' &&
                                                !isAgentThinking &&
                                                agentInput.trim() &&
                                                (isAwaitingClarification ? handleClarificationResponse() : handleInitialAgentMessage())
                                            }
                                        />
                                        <button
                                            onClick={isAwaitingClarification ? handleClarificationResponse : handleInitialAgentMessage}
                                            disabled={isAgentThinking || !agentInput.trim()}
                                        >
                                            {isAwaitingClarification ? "Submit Clarification" : "Start"}
                                        </button>
                                    </div>
                                )}
                                {isAwaitingClarification && (
                                    <div className={styles.clarificationNote}>
                                        Please provide the requested clarification above before continuing.
                                    </div>
                                )}
                                {isAgentTaskComplete && (
                                    <button onClick={saveLastAgentMessage} className={styles.saveButton}>
                                        Save Last Agent A Message
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </>
            ) : isLoading ? (
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <p>Checking access...</p>
                </div>
            ) : (
                <div className={styles.connectPrompt}>
                    <p>You do not have access to Argo AI. You need to own an Argo the Puppy ordinal to access this feature.</p>
                    <button onClick={handleDisconnect} className={styles.disconnectButton}>
                        Disconnect
                    </button>
                </div>
            )}
            <CombinedLoginModal
                isOpen={isLoginModalOpen}
                onClose={closeLoginModal}
                onConnect={handleWalletConnect}
            />
            {showMemeChartPopup && (
                <div className={styles.popupOverlay}>
                    <div className={styles.popupContent}>
                        <button className={styles.closeButton} onClick={() => setShowMemeChartPopup(false)}>
                            ×
                        </button>
                        <h2>Enter MemeChart Details</h2>
                        <form onSubmit={handleMemeChartSubmit}>
                            <input
                                type="text"
                                placeholder="Contract Address"
                                value={memeChartContract}
                                onChange={(e) => setMemeChartContract(e.target.value)}
                                required
                            />
                            <select value={memeChartChain} onChange={(e) => setMemeChartChain(e.target.value)}>
                                <option value="ethereum">Ethereum</option>
                                <option value="bsc">BSC</option>
                                <option value="polygon">Polygon</option>
                                <option value="solana">Solana</option>
                            </select>
                            <button type="submit">Submit</button>
                            <button type="button" onClick={() => setShowMemeChartPopup(false)}>
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
            {showTokenDataPopup && (
                <div className={styles.popupOverlay}>
                    <div className={styles.popupContent}>
                        <h2>Enter Token Details</h2>
                        <form onSubmit={handleFetchTokenData}>
                            <input
                                type="text"
                                placeholder="Token Address"
                                value={tokenAddress}
                                onChange={(e) => setTokenAddress(e.target.value)}
                                required
                            />
                            <button type="submit">Submit</button>
                            <button type="button" onClick={toggleTokenDataPopup}>
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatComponent;