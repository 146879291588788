import React, { useState, useEffect } from 'react';
import styles from './ChatBack.module.css';

const BitcoinPrice: React.FC = () => {
  const [price, setPrice] = useState<string>('Loading...');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [trend, setTrend] = useState<'up' | 'down' | 'stable'>('stable');
  const [lastPrice, setLastPrice] = useState<number | null>(null);

  const fetchBitcoinPrice = async () => {
    try {
      setIsLoading(true);
      setError(false);
      
      // Using CoinCap API which has better CORS support
      const response = await fetch('https://api.coincap.io/v2/assets/bitcoin');
      
      if (!response.ok) {
        throw new Error('Failed to fetch Bitcoin price');
      }
      
      const data = await response.json();
      const currentPrice = parseFloat(data.data.priceUsd);
      
      // Determine price trend for styling
      if (lastPrice !== null) {
        if (currentPrice > lastPrice) {
          setTrend('up');
        } else if (currentPrice < lastPrice) {
          setTrend('down');
        } else {
          setTrend('stable');
        }
      }
      
      // Update last price for next comparison
      setLastPrice(currentPrice);
      
      // Format the price with commas and no decimal places
      const formattedPrice = new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(currentPrice);
      
      setPrice(formattedPrice);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Bitcoin price:', error);
      setError(true);
      setIsLoading(false);
      // Keep the last price if there was an error
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchBitcoinPrice();
    
    // Set up interval to fetch every 15 seconds
    const interval = setInterval(fetchBitcoinPrice, 15000);
    
    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.bitcoinPriceContainer}>
      Bitcoin: {isLoading && !price.startsWith('$') ? (
        <span className={styles.loadingPrice}>Loading...</span>
      ) : error ? (
        <span className={styles.errorPrice}>--</span>
      ) : (
        <span className={`${styles.bitcoinPrice} ${
          trend === 'up' ? styles.priceUp : 
          trend === 'down' ? styles.priceDown : 
          styles.priceStable
        }`}>
          {price}
        </span>
      )}
    </div>
  );
};

export default BitcoinPrice;