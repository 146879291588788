import React, { useState, useEffect } from 'react';
import styles from '../styles/Watchlist.module.css';

interface PermanentOrdinalItemProps {
  onOpenPriceChart?: () => void;
}

const PermanentOrdinalItem: React.FC<PermanentOrdinalItemProps> = ({ onOpenPriceChart }) => {
  const [price, setPrice] = useState<string | number>('Loading...');
  const [priceUnit, setPriceUnit] = useState<string>('BTC');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchOrdinalFloor = async () => {
    try {
      setIsLoading(true);
      const API_URL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === 'development' ? 'http://localhost:5008' : 'https://api.argothepuppy.org');
      
      const response = await fetch(
        `${API_URL}/api/proxy/magiceden/ord/btc/stat?collectionSymbol=argothepuppy`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const floorPrice = data.floorPrice / 100000000; // Convert satoshis to BTC
      
      setPrice(floorPrice.toFixed(5).replace(/\.?0+$/, '')); // Remove trailing zeros
      setPriceUnit('BTC');
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching ordinal floor price:', error);
      setPrice('--');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdinalFloor();
    
    // Set interval to refresh price every 15 seconds
    const interval = setInterval(fetchOrdinalFloor, 15000);
    
    // Clean up interval
    return () => clearInterval(interval);
  }, []);

  // Format price with special handling for very small values
  const formatPrice = (item: { price: string | number, priceUnit: string }) => {
    if (typeof item.price === 'string' && ['Error', 'N/A', '--', 'Loading...'].includes(item.price)) {
      return item.price;
    }

    const priceValue = typeof item.price === 'number' ? item.price : Number(item.price);
    if (isNaN(priceValue)) return 'N/A';

    if (item.priceUnit === 'BTC') {
      return `${priceValue.toFixed(5).replace(/\.?0+$/, '')} ${item.priceUnit}`;
    }

    return `${priceValue.toFixed(2)} ${item.priceUnit}`;
  };

  const priceDisplay = formatPrice({ price, priceUnit });

  return (
    <div className={`${styles.item} ${styles.permanentItem}`} onClick={onOpenPriceChart}>
      <div className={styles.itemContent}>
        <span className={styles.itemName}>Argo the Puppy
          {/* No remove button for permanent item */}
          <span className={styles.permanentIndicator}>★</span>
        </span>
        <span className={styles.itemPrice}>
          {isLoading ? 'Loading...' : priceDisplay}
        </span>
      </div>
    </div>
  );
};

export default PermanentOrdinalItem;